import './CreateCarPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { createLocation } from '../../api/locations';
import { ICar, ICarCreate, ICarInfo, ICarrier } from '../../types/api';
import { listRetailers } from '../../api/retailers';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import { createCar } from '../../api/carriersAndCars';
import WeekDaySelector from '../../hoc/Datepicker/Datepicker';

interface ICreateCarPopupProps {
  showPopup: boolean;
  onClose: () => void;
  carriers: ICarrier[];
  cars: ICar[];
  onConfirm: () => void;  
}

function CreateCarPopup(props: ICreateCarPopupProps) {
  const [name, setName] = useState<string>('');
  const [carrierId, setCarrierId] = useState<number>();
  const [unitCapacity, setUnitCapacity] = useState<number>(0);
  const [weekday, setWeekday] = useState<number>(0);    
  const [pickupHour, setPickupHour] = useState<number>(0);
  const [pickupTime, setPickupTime] = useState<string>('');
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'carrierId':
        setCarrierId(Number(value));
        break;
      case 'unitCapacity':
        setUnitCapacity(Number(value));
        break;
      case 'weekday':
        setWeekday(Number(value));
        break;
      case 'pickupTime':
        setPickupTime(value);
        const hours = parseInt(value.split(':')[0], 10);
        setPickupHour(hours);
        break;
      default:
        break;
    }
  };

  const getUnixTimeForHour = (hour: number) => {
    const date = new Date();
    date.setHours(hour, 0, 0, 0);
    return Math.floor(date.getTime() / 1000);
  };
  const timeOptions = [];
  for (let hour = 6; hour <= 18; hour++) {
    const unixTime = getUnixTimeForHour(hour);
    timeOptions.push(
      <option key={unixTime} value={unixTime}>{`${hour}:00`}</option>
    );
  }

  // const addScheduleToCar = (carIndex: number) => {
  // const updatedCars = [...cars];
  // const newSchedule = {
  //     locationId: 0,
  //     pickupWeekDay: 0,
  //     deliveryWeekDay: 0,
  //     createdAt: Date.now(),
  // };

  // updatedCars[carIndex].schedules = [
  //     ...updatedCars[carIndex].schedules,
  //     newSchedule,
  // ];
  // setCars(updatedCars);
  // };
const weekdays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];

  async function createCarHandler() {

    const carToCreate: Partial<ICarCreate> = {
      name,
      carrierId,
      unit: 'back',
      unitCapacity,
      pickupDay: weekday,
      pickupHour: pickupHour,
      active: true,
    };

    try {
      await createCar(carToCreate);
      console.log('Car created successfully');
      
      props.onClose();
      props.onConfirm();
    } catch (error) {
      console.error('Error creating car:', error);
    }
  }


  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Lägg till ny bil</span>}
    >
      <div className="create-car-popup">
        <form
          className="create-car-form"
          onSubmit={(e) => {
            e.preventDefault();
            createCarHandler();
          }}
        >
          <div className="car-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-car-name"
              placeholder="Namn på bilen"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="car-section">
            <span className="text-m-m">Koppla till distributör</span>
            <select
              className="s-car-carrier"
              name="carrierId"
              value={carrierId !== null ? carrierId : 1}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Välj distributör
              </option>
              {props.carriers.map((carrier) => (
                <option key={carrier.id} value={carrier.id}>
                  {carrier.name}
                </option>
              ))}
            </select>
          </div>

          <div className="car-section">
            <span className="text-m-m">Last kapacitet i backar</span>
            <input
              className="i-car-capacity"
              placeholder="Kapacitet i back"
              name="unitCapacity"
              value={unitCapacity}
              type="number"
              onChange={handleInputChange}
            />
          </div>
          <div className="car-section">
            <span className="text-m-m">Upphämtningsdag</span>
            <div className="car-pickup-estimate">
              <select value={weekday} className="weekday-selector"name="weekday"onChange={handleInputChange}>
                <option disabled>Välj dag</option>  
                {weekdays.map((weekday, index) => (
                  <option key={index} value={index}>
                    {weekday}
                  </option>
                ))}
              </select>
              <input
                type="time"
                id="appt"
                name="pickupTime"
                min="07:00"
                max="18:00"
                value={pickupTime} 
                onChange={handleInputChange} 
                required
              />
            </div>
          </div>

        

          <div className="carrier-save-wrapper">
            <button className="green-button carrier-save-button" type="submit">
              <span className="text-m-m">Lägg till bil</span>
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateCarPopup;
