import { NavLink } from "react-router-dom";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums"
import "./Navbar.scss"
import { ReactComponent as LjusgardaSvg } from "../../assets/images/ljusgarda-logo.svg";

interface INavbarProps {}

function Navbar(props: INavbarProps) { 

    const navItems = [
        {
            label: ROUTE_LABEL.Sales,
            path: ROUTE.Sales,
        },
        {
            label: ROUTE_LABEL.Locations,
            path: ROUTE.Locations,
        },
        {
            label: ROUTE_LABEL.Communication,
            path: ROUTE.Communication,
        },
        {
            label: ROUTE_LABEL.Forecast,
            path: ROUTE.Forecast,
        },
    ];

    return (
      <nav className="navbar">
        <div className="nav-items--wrapper">
          <NavLink key="hem" to="/" className="nav-item nav-item-logo" exact={true}>
            <LjusgardaSvg width="100" height="30px" />
          </NavLink>
          <div className="nav-items">
            {navItems.map((nav) => (
              <NavLink
                key={nav.label}
                to={nav.path}
                className="nav-item text-m-m"
                exact={true}
              >
                {nav.label}
              </NavLink>
            ))}
            <NavLink
            to={ROUTE.Settings}
            className="nav-item nav-item-settings"
            >
                <i className="fa-solid fa-gear"></i>
            </NavLink>
          </div>
        </div>
      </nav>
    );
    
}

export default Navbar;