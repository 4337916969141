import './SchedulesOrderCard.scss';
import { AggregatedOrder, IProducts } from '../../../types/api';
import AmountCard from '../../../hoc/AmountCard/AmountCard';
import { predefinedVariants } from '../../../shared/enums';
import Button from '../../UI/Button/Button';

interface ISchedulesOrderCardProps {
  order: AggregatedOrder;
 
}
function SchedulesOrderCard(props: ISchedulesOrderCardProps) {
 

 const getAmountForVariant = (productVariant: string, productName: string) => {
   return props.order.orderDetails.reduce((total, orderDetail) => {
     if (
       orderDetail.productVariant === productVariant &&
       orderDetail.productName === productName
     ) {
       return total + orderDetail.amount;
     }
     return total;
   }, 0);
 };
 
  if(!props.order) 
  return null

  return (
    <div className="carrier-schedule-order-card">
      <div className="carrier-schedule-order-card-top">
        <div className="carrier-schedule-order-card-top-left">
          <span className="text-l-s cut-length">{props.order.locationName}</span>
        </div>
      </div>
      <div className="carrier-schedule-order-card-bottom">
        {predefinedVariants.map((variant, index) => (
          <AmountCard
            key={index}
            index={index}
            variant={variant}
            getAmountForVariant={getAmountForVariant}
          />
        ))}
      </div>
    </div>
  );
}

export default SchedulesOrderCard;
