import './SettingsOdlingPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import Button from '../../components/UI/Button/Button';


interface ISettingsOdlingPopupProps {
  showPopup: boolean;
  onClose: () => void;
}

function SettingsOdlingPopup(props: ISettingsOdlingPopupProps) {
  
  function handleInputChange() {
        
    }

  function addEdgeCase() {

  }

  function handleSaveSettings() {

  }
function settingsOdlingHandler() {

}
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-l-m">Information från odlingen</span>
      }
    >
      <div className="settings-odling-popup">
        <form
          className="settings-odling-form"
          onSubmit={(e) => {
            e.preventDefault();
            settingsOdlingHandler();
          }}
        >
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">
                Krisp (gram/planta) = Cultivation promise
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">
                Mix (gram/planta) = Cultivation promise
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">
                Krisp DAS (days after sowing) = Cycle
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">
                Krisp DAT (days after sowing) = Cycle
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">
                Mix DAS (days after sowing) = Cycle
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section last-setting-section">
            <div className="setting-text">
              <span className="text-l-s">
                Mix DAT (days after sowing) = Cycle
              </span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>

          <div className="save-settings-wrapper">
            <Button
              color="secondary"
              label="Uppdatera information från odling"
              onClick={handleSaveSettings}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default SettingsOdlingPopup;
