export const BACKEND_BASE_URL = `https://api.supernormalgreens.se`;
//export const BACKEND_BASE_URL = `http://localhost:8080`;
export const REDIRECT_URL = `https://app.supernormalgreens.se/auth/azuread/callback`;
//export const REDIRECT_URL = `https://ljusgarda.techmdw.com:2083/admin/auth/azuread/callback`;
//export const REDIRECT_URL = `http://localhost:3000/auth/azuread/callback`;
export type ILocation = {
  id: number;
  name: string;
  zipcode: string;
  address1: string;
  city: string;
  country: string;
  address2?: string;
};

export interface ListNotificationsHistoryResponse {
  id: number;
  status: number;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  locationName: string;
}

export interface GetNotificationHistoryData {
  id: number;
  notificationId: number;
  respondedAt: Date;
  text: string;
  status: number;
  isReminder: boolean;
  parent: number;
  createdAt: Date;
  updatedAt: Date;
  scheduleWeekDay: string;
  scheduleTime: string;
  locationName: string;
  firstName: string;
  lastName: string;
}

export enum AliceGretaNotificationStatus {
  pending = 0,
  review = 1,
  confirm = 2,
  sent = 3,
  failed = 4,
  cancelled = 5,
}
