
import Button from '../../components/UI/Button/Button';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';
import Popup from '../../hoc/Popup/Popup';
import './EditOrderPopup.scss';
import React, { useState, useEffect } from 'react';
import {
  AggregatedOrder,
  ICar,
  IEditShipmentBulk,
} from '../../types/api';
import { predefinedVariants } from '../../shared/enums';
import { formatDateToSwedish } from '../../shared/utility';
import {
  deleteShipment,
  updateShipmentsBulk,
} from '../../api/shipments';

interface IEditOrderPopupProps {
  showPopup: boolean;
  onClose: () => void;
  locationName: string;
  cars: ICar[];
  order: AggregatedOrder;
  onConfirm: () => void;
}

interface VariantAmounts {
  [key: string]: number;
}

function EditOrderPopup(props: IEditOrderPopupProps) {
  const [orderDetails, setOrderDetails] = useState(props.order.orderDetails);


  const [carId, setCarId] = useState<number | null>(null);

  const { weekday, dateAndMonth, hour } = formatDateToSwedish(
    new Date(
      props.order.shipmentCreatedAt
        ? props.order.shipmentCreatedAt
        : props.order.shipmentSchedule.carrierPickupEstimate
    )
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'carId':
        setCarId(value ? Number(value) : null);
        break;
      // Add more cases if needed
      default:
        break;
    }
  };

  const initialVariantAmounts: VariantAmounts = predefinedVariants.reduce(
    (acc, variant) => {
      const found = props.order.orderDetails.find(
        (orderDetail) =>
          orderDetail.productVariant === variant.productVariant &&
          orderDetail.productName === variant.product
      );
      acc[`${variant.product}-${variant.productVariant}`] = found
        ? found.amount
        : 0;
      return acc;
    },
    {} as VariantAmounts
  );

  const [variantAmounts, setVariantAmounts] = useState(initialVariantAmounts);

  const handleAmountChange = (variantKey: string, newAmount: number) => {
    setVariantAmounts((prevAmounts) => ({
      ...prevAmounts,
      [variantKey]: newAmount,
    }));
    setOrderDetails((prevOrderDetails) => {
      const updatedOrderDetails = prevOrderDetails.map((orderDetail) => {
        if (
          `${orderDetail.productName}-${orderDetail.productVariant}` ===
          variantKey
        ) {
          return { ...orderDetail, amount: newAmount };
        }
        return orderDetail;
      });
      return updatedOrderDetails;
    });
  };

  useEffect(() => {
    setCarId(props.order.shipmentSchedule.carId);
  }, [props.order]);

  async function editOrderHandler() {
    const hasCarId = carId !== null;
    const orderToEdit: IEditShipmentBulk = {
      entries: orderDetails.map((orderDetail) => ({
        id: orderDetail.orderId,
        amount: orderDetail.amount,
        carId: hasCarId ? carId : null,
      })),
    };

    try {
      const response = await updateShipmentsBulk(orderToEdit);

      console.log('Orders updated successfully:', response);
      props.onConfirm();
      props.onClose();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  }

  async function deleteOrderHandler() {
   
    if (window.confirm('Är du säker på att du vill radera ordern?')) {
     try {
       const deletePromises = props.order.orderDetails.map(
         (orderDetail) => deleteShipment(orderDetail.orderId) 
       );

       const responses = await Promise.all(deletePromises);
       console.log('Orders deleted successfully:', responses);
       props.onConfirm();
       props.onClose();
     } catch (error) {
       console.error('Error deleting orders:', error);
     }
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Redigera order</span>}
    >
      <div className="edit-order-popup">
        <form
          className="edit-order-form"
          onSubmit={(e) => {
            e.preventDefault();
            editOrderHandler();
          }}
        >
          <div className="order-section order-info-top">
            <div className="text-m-m">{props.locationName}</div>
            <div className="order-carrier">
              {/* <i className="far fa-link text-color-gray" /> */}
              <span className="text-m-m">
                Orderdatum: {weekday}, {dateAndMonth}
              </span>
            </div>
          </div>
          <div className="order-section">
            <div className="order-variants-flexer">
              {predefinedVariants.map((variant, index) => {
                const variantKey = `${variant.product}-${variant.productVariant}`;
                return (
                  <div key={index} className="order-variant-section">
                    <div className={`salad-dot-wrapper ${variant.class}`}>
                      <div className={`dot ${variant.class}`} />
                      <span className="text-s-m">{variant.title}</span>
                    </div>
                    <input
                      type="number"
                      min="1"
                      value={variantAmounts[variantKey]}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        handleAmountChange(
                          variantKey,
                          newValue === '' ? 0 : Math.max(0, parseInt(newValue))
                        );
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="order-section">
            <span className="text-m-m">Bil</span>
            <select
              className="s-location-car"
              name="carId"
              value={carId || ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Välj bil/leveransdag
              </option>

              {props.cars.map((car) => (
                <option key={car.id} value={car.id}>
                  {car.name}
                </option>
              ))}
            </select>
          </div>
          <div className="order-save-delete-wrapper">
            <Button
              onClick={editOrderHandler}
              className="green-button"
              color={'secondary'}
              label={'Spara ändringar för order'}
              short={true}
            />
            <DeleteButton
              onClick={deleteOrderHandler}
              color={'primary'}
              label={'Radera order'}
              icon={'del-40'}
              short={true}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default EditOrderPopup;
