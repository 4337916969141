import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useEffect } from 'react';
import './Popup.scss';

interface IPopupProps {
  children: React.ReactNode;
  showPopup: boolean;
  onClose: () => void;
  className?: string;
  width?: string;
  leftTopElement?: React.ReactNode;
  style?: React.CSSProperties;
}

function Popup(props: IPopupProps) {
  useEffect(() => {
    if (props.showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.showPopup]);

  const popupClasses = ['popup'];
  if (props.className) {
    popupClasses.push(props.className);
  }

  const popupVariants: Variants = {
    closed: {
      x: 600,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  const popupWrapperVariants: Variants = {
    closed: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      transition: {
        duration: 0.3,
      },
    },
    open: {
      backgroundColor: 'rgba(3, 2, 41, 0.4)',
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <AnimatePresence>
      {props.showPopup ? (
        <motion.div
          className="popup-wrapper"
          variants={popupWrapperVariants}
          initial="closed"
          animate={props.showPopup ? 'open' : 'closed'}
          exit="closed"
        >
          <motion.div
            className={popupClasses.join(' ')}
            variants={popupVariants}
            initial="closed"
            animate={props.showPopup ? 'open' : 'closed'}
            exit="closed"
            style={{ ...props.style, width: props.width }}
          >
            <div className="top">
              {props.leftTopElement || null}
              <div className="close-button-wrapper">
                Stäng
                <button
                  className="close-button waves-effect"
                  onClick={props.onClose}
                >
                  <i className="fa-regular fa-xmark" />
                </button>
              </div>
            </div>
            {props.children}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default Popup;
