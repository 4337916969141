import { useEffect, useState } from 'react';
import './Home.scss';
import { eachWeekOfInterval, lightFormat, set } from 'date-fns';
import WeekDaySelector from '../../hoc/Datepicker/Datepicker';
import OrderCard from '../../components/OrderCard/OrderCard';
import Order from '../../components/Order';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import TotalOrdersCard from '../../components/TotalOrdersCard/TotalOrdersCard';
import { captureRejectionSymbol } from 'events';
import {
  IShipment,
  AggregatedOrder,
  IProducts,
  IProductVariants,
  ICarrierSchedules,
  ICarrier,
  IScheduleForDate,
  ICar,
  ILocation,
  ILocationAll,
  IShipmentScheduleNested,
} from '../../types/api';
import {
  getProductVariant,
  listProductVariants,
  listProducts,
} from '../../api/product';
import { listCars, listCarriers } from '../../api/carriersAndCars';
import { listShipments, getShipmentSchedule } from '../../api/shipments';
import SchedulesCard from '../../components/SchedulesCard/SchedulesCard';
import { makeWeekdayActive } from '../../shared/utility';
import { getUnixTime } from 'date-fns';
import Button from '../../components/UI/Button/Button';
import TotalSchedulesCard from '../../components/TotalSchedulesCard/TotalSchedulesCard';
import TotalDeparturesCard from '../../components/TotalDeparturesCard/TotalDeparturesCard';
import CreateOrderPopup from '../../containers/CreateOrderPopup/CreateOrderPopup';
import { listAllLocations } from '../../api/locations';
import DeparturesCard from '../../components/DeparturesCard/DeparturesCard';
import Spinner from '../../components/UI/Spinner/Spinner';

interface IHomeProps {}

function Home(props: IHomeProps) {
  const [isCreateOrderPopupOpen, setIsCreateOrderPopupOpen] = useState(false);
  const [locations, setLocations] = useState<ILocationAll[]>([]);
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState<ICar[]>([]);
  const [incommingShipmentsStatus0, setIncommingShipmentsStatus0] = useState<
    IShipment[]
  >([]);
  const [incommingShipmentsStatus1, setIncommingShipmentsStatus1] = useState<
    IShipment[]
  >([]);
  const [incommingShipmentsStatus2, setIncommingShipmentsStatus2] = useState<
    IShipment[]
  >([]);
  const [incommingShipmentsStatus5, setIncommingShipmentsStatus5] = useState<
    IShipment[]
  >([]);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);

  const [activeFilterDay, setActiveFilterDay] = useState<number>(0);

  const [isFetchingShipments, setIsFetchingShipments] =
    useState<boolean>(false);

  const fetchIncommingShipments = async (
    status0: boolean,
    status1: boolean,
    status2: boolean,
    status5: boolean
  ) => {
    const tasks: Promise<any>[] = [];

    if (status0) {
      tasks.push(
        listShipments(filterDate, 0, undefined, filterDate)
          .then((data) => ({ status: 0, data }))
          .catch((error) => ({ status: 0, error }))
      );
    }
    if (status1) {
      tasks.push(
        listShipments(filterDate, 1, undefined, filterDate)
          .then((data) => ({ status: 1, data }))
          .catch((error) => ({ status: 1, error }))
      );
    }
    if (status2) {
      tasks.push(
        listShipments(filterDate, 2, undefined, filterDate)
          .then((data) => ({ status: 2, data }))
          .catch((error) => ({ status: 2, error }))
      );
    }
    if (status5) {
      tasks.push(
        listShipments(filterDate, 5, undefined, filterDate)
          .then((data) => ({ status: 5, data }))
          .catch((error) => ({ status: 5, error }))
      );
    }

    setIsFetchingShipments(true);
    const results = await Promise.all(tasks);
    setIsFetchingShipments(false);

    results.forEach((result) => {
      if (result.error) {
        console.error(
          `Error fetching shipments for status ${result.status}:`,
          result.error
        );
        return;
      }
      switch (result.status) {
        case 0:
          setIncommingShipmentsStatus0(result.data.data); // Assuming result.data contains the data
          break;
        case 1:
          setIncommingShipmentsStatus1(result.data.data);
          break;
        case 2:
          setIncommingShipmentsStatus2(result.data.data);
          break;
        case 5:
          setIncommingShipmentsStatus5(result.data.data);
          break;
        default:
          // Handle unexpected status if needed
          break;
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (filterDate) {
      const active = filterDate.toString();
      setActiveFilterDay(makeWeekdayActive(active));
    }
    async function fetchCarrierSchedules() {
      const response = await listCars();
      setCars(response.data);
    }
    async function fetchCarriers() {
      const response = await listCarriers();
      setCarriers(response.data);
    }

    async function fetchAllLocations() {
      const response = await listAllLocations();
      setLocations(response.data);
    }

    fetchAllLocations();
    fetchIncommingShipments(true, true, true, true);
    fetchCarriers();
    fetchCarrierSchedules();
    setIsLoading(false);
  }, [filterDate]);

  const year = new Date().getFullYear();
  const dates = eachWeekOfInterval({
    start: new Date(year, 0, 1),
    end: new Date(year, 11, 31),
  }).map((date) => lightFormat(date, 'yyyy'));

  const aggregateOrders = (
    orders: IShipment[],
    filterDate: Date | null,
    shipmentStatusFilter: number,
    dateField: keyof Pick<IShipmentScheduleNested, 'carrierPickupEstimate'>
  ): AggregatedOrder[] => {
    const filteredOrders = orders.filter((order) => {
      if (!filterDate) return true;

      const dateValue = order.shipmentSchedule[dateField];

      if (!dateValue) return false;
      const dateToCompare = new Date(dateValue);

      const format = (date: Date) => {
        const localDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        return localDate.toISOString().split('T')[0];
      };

      const dateString = format(dateToCompare);
      const comparisonDateString = format(filterDate);

      return (
        order.shipmentStatus === shipmentStatusFilter &&
        dateString === comparisonDateString
      );
    });

    const groupedOrders: { [key: string]: AggregatedOrder } = {};

    filteredOrders.forEach((order) => {
      const key = `${order.locationId}`;
      const productName = [1, 2].includes(order.productVariantId)
        ? 'krisp'
        : 'mix';
      const productVariant = order.productVariantId % 2 === 0 ? '150g' : '60g';

      if (!groupedOrders[key]) {
        groupedOrders[key] = {
          id: order.id,
          locationId: order.locationId,
          locationName: order.locationName,
          location: {
            address1: order.location.address1,
            city: order.location.city,
            country: order.location.country,
            zipCode: order.location.zipCode,
          },
          shipmentStatus: order.shipmentStatus,
          shipmentCreatedAt: order.shipmentCreatedAt,

          carrierId: order.carrierId,
          shipmentSchedule: {
            id: order.shipmentSchedule.id,
            name: order.shipmentSchedule.name ?? 'Default Name',
            carId: order.shipmentSchedule.carId,
            carrierPickupEstimate: order.shipmentSchedule.carrierPickupEstimate,
            carrierPickupDelivered:
              order.shipmentSchedule.carrierPickupDelivered,
          },
          orderDetails: [],
          carName: order.carName,
        };
      }

      groupedOrders[key].orderDetails.push({
        orderId: order.id,
        productVariantId: order.productVariantId,
        productName: productName,
        productVariant: productVariant,
        amount: order.amount,
      });
    });

    return Object.values(groupedOrders);
  };

  //pending
  const aggregatedOrdersStatus0 = aggregateOrders(
    incommingShipmentsStatus0,
    filterDate,
    0,
    'carrierPickupEstimate'
  );
  //planned
  const aggregatedOrdersStatus1 = aggregateOrders(
    incommingShipmentsStatus1,
    filterDate,
    1,
    'carrierPickupEstimate'
  );

  //shipped
  const aggregatedOrdersStatus2 = aggregateOrders(
    incommingShipmentsStatus2,
    filterDate,
    2,
    'carrierPickupEstimate'
  );

  //canceled
  const aggregatedOrdersStatus5 = aggregateOrders(
    incommingShipmentsStatus5,
    filterDate,
    5,
    'carrierPickupEstimate'
  );

  const openCreateOrderPopup = () => {
    setIsCreateOrderPopupOpen(true);
  };

  const schedules: IScheduleForDate[] = [];

  aggregatedOrdersStatus0.forEach((order) => {
    if (order.shipmentSchedule.id) {
      const existingSchedule = schedules.find(
        (schedule) => schedule.shipmentScheduleId === order.shipmentSchedule.id
      );

      if (existingSchedule) {
        existingSchedule.orders.push(order);
      } else {
        schedules.push({
          shipmentScheduleId: order.shipmentSchedule.id,
          orders: [order],
        });
      }
    }
  });

  const departures: IScheduleForDate[] = [];
  const aggregatedOrdersStatus1And2 = aggregatedOrdersStatus2.concat(
    aggregatedOrdersStatus1
  );
  aggregatedOrdersStatus1And2.forEach((order) => {
    if (order.shipmentSchedule.id) {
      const existingSchedule = departures.find(
        (schedule) => schedule.shipmentScheduleId === order.shipmentSchedule.id
      );

      if (existingSchedule) {
        existingSchedule.orders.push(order);
      } else {
        departures.push({
          shipmentScheduleId: order.shipmentSchedule.id,
          orders: [order],
        });
      }
    }
  });

  if (
    !aggregatedOrdersStatus0 ||
    !aggregatedOrdersStatus1 ||
    !aggregatedOrdersStatus2 ||
    !aggregatedOrdersStatus5 ||
    isLoading ||
    filterDate === null
  ) {
    return null;
  } else {
    console.log(incommingShipmentsStatus0, 'incommingShipmentsStatus0', incommingShipmentsStatus1, 'incommingShipmentsStatus1', incommingShipmentsStatus2, 'incommingShipmentsStatus2', incommingShipmentsStatus5, 'incommingShipmentsStatus5' ); 
  }
  return (
    <div className="home-page-wrapper">
      <div className="home-week-filter-wrapper">
        <WeekDaySelector
          value={filterDate}
          onChange={setFilterDate}
          includeWeekdays
        />
        <div className="home-salad-package-dots-wrapper">
          <div className="salad-dot-wrapper">
            <div className="dot mix-60" />
            <span className="text-s-m">Mix (60)</span>
          </div>
          <div className="salad-dot-wrapper">
            <div className="dot mix-150" />
            <span className="text-s-m">Mix (150)</span>
          </div>
          <div className="salad-dot-wrapper">
            <div className="dot krisp-60" />
            <span className="text-s-m ">Krisp (60)</span>
          </div>
          <div className="salad-dot-wrapper">
            <div className="dot krisp-150" />
            <span className="text-s-m">Krisp (150)</span>
          </div>
        </div>
      </div>
      <div className="home-grid-layout">
        <div className="home-order-column">
          <div className="order-column-total-sales-wrapper">
            <TotalOrdersCard allOrders={aggregatedOrdersStatus5} />
          </div>
          <div className="home-order-list-wrapper">
            <CreateButton
              color={'primary'}
              label={'Lägg till order'}
              icon={'plus-30'}
              onClick={openCreateOrderPopup}
            />

            <CreateOrderPopup
              showPopup={isCreateOrderPopupOpen}
              onClose={() => setIsCreateOrderPopupOpen(false)}
              cars={cars}
              locations={locations}
              onConfirm={() =>
                fetchIncommingShipments(false, false, false, true)
              }
              filterDate={filterDate}
            />
            {isFetchingShipments ? (
              <Spinner />
            ) : (
              aggregatedOrdersStatus5.map((order, orderIndex) => (
                <OrderCard
                  order={order}
                  key={orderIndex}
                  currentFilterDate={filterDate}
                  cars={cars}
                  onConfirm={() =>
                    fetchIncommingShipments(true, false, false, true)
                  }
                />
              ))
            )}
          </div>
        </div>
        <div className="home-carrier-schedules-wrapper">
          <div className="carrier-schedules-column-total-schedules-wrapper">
            <TotalSchedulesCard
              allSchedules={schedules}
              activeFilterDay={activeFilterDay}
              carriers={carriers}
              currentFilterDate={filterDate}
            />
          </div>
          <div className="home-carrier-schedules-list-wrapper">
            <div className="home-carrier-schedules-filter-wrapper">
              <Button
                label={'Skapade'}
                color={'secondary'}
                onClick={() => {}}
                short
              />
              <Button
                label={'Ej skapade'}
                color={'secondary'}
                onClick={() => {}}
                short
              />
            </div>

            {isFetchingShipments ? (
              <Spinner />
            ) : (
              schedules.map((schedule, index) => (
                <SchedulesCard
                  key={index}
                  schedule={schedule}
                  activeFilterDay={activeFilterDay}
                  orders={schedule.orders}
                  carriers={carriers}
                  currentFilterDate={filterDate}
                  onConfirm={() =>
                    fetchIncommingShipments(true, true, false, false)
                  }
                  onRemoveFromSchedule={() => fetchIncommingShipments(true, false, false, true)}
                />
              ))
            )}
          </div>
        </div>
        <div className="home-departures-wrapper">
          <div className="departures-column-total-departures-wrapper">
            <TotalDeparturesCard
              allSchedules={departures}
              activeFilterDay={activeFilterDay}
              carriers={carriers}
              currentFilterDate={filterDate}
            />
          </div>
          <div className="home-departure-schedules-list-wrapper">
            <div className="home-departure-schedules-btns-wrapper">
              <CreateButton
                label={'Lägg till bil'}
                color={'secondary'}
                onClick={() => {}}
                short={true}
                icon={'plus-30'}
              />
            </div>

            {isFetchingShipments ? (
              <Spinner />
            ) : (
              departures.map((departure, index) => {
                const lastadStatus =
                  departure.orders[0].shipmentStatus === 2 ? true : false;
                return (
                  <DeparturesCard
                    key={index}
                    schedule={departure}
                    activeFilterDay={activeFilterDay}
                    orders={departure.orders}
                    carriers={carriers}
                    currentFilterDate={filterDate}
                    onLastadToggle={() => fetchIncommingShipments(false, true, true, false)}
                    onRemoveDeparture={() =>
                      fetchIncommingShipments(true, true, false, false)
                    }
                    lastadStatus={lastadStatus}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
