import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React, { useEffect, useState } from 'react';
import { IExcelExportData } from '../types/excel';
import Button from '../components/UI/Button/Button';
import { AggregatedOrder } from '../types/api';
import { changeShipmentStatus, updateShipmentsBulkAlt } from '../api/shipments';

interface ICreateExcelExportProps {
  excelData: IExcelExportData[];
  orders: AggregatedOrder[];
  onConfirm: () => void;
  confirmShipments: boolean;
  label:string;
}

const columnWidthsNarkeSk = [
  { wpx: 100 },
  { wpx: 200 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 117 },
  { wpx: 89 },
  { wpx: 89 },
  { wpx: 125 },
  { wpx: 87 },
  { wpx: 110 },
  { wpx: 170 },
];

const headersNarkeSk = [
'Terminal',
'Butiker',
'60g krisp',
'60g mix',
'150g krisp',
'150g mix',
'400g krisp',
'400g mix',
,
'TOT antal backar',
'TOT antal pall',
'TOT antal PPL',
'Gatuadress',
'Postnummer',
'Ort',
'Märkning (följesedelsnummer)',
]

const columnWidthsLocal = [
  { wpx: 341 },
  { wpx: 166 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 53 },
  { wpx: 173 },
  { wpx: 105 },
];

const headersLocal = [
  'Adress',
  'Butik',
  '60g krisp',
  '60g mix',
  '150g krisp',
  '150g mix',
  'Lämnade pallar',
  'Märkning (följesedelsnummer)',
];
interface GroupedOrders {
  [key: string]: IExcelExportData[];
}

function groupOrdersByDeliveryDay(orders: IExcelExportData[]): GroupedOrders {
  const grouped: GroupedOrders = {};

  orders.forEach((order) => {
    const day = order.deliveryDay;
    if (!grouped[day]) {
      grouped[day] = [];
    }
    grouped[day].push(order);
  });

  return grouped;
}

export function CreateExcelExport(props: ICreateExcelExportProps) {

  const groupedOrders = groupOrdersByDeliveryDay(props.excelData);
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet([], { skipHeader: true });
      console.log(props.orders);
    let currentRow = 1;

    Object.keys(groupedOrders).forEach((day) => {
      const orders = groupedOrders[day];
      const distributorName = orders[0].distributorName;

      const useNarkeSk =
        distributorName === 'Närke' ||
        distributorName === 'Skaraborg Kyltransport';
      const headers = useNarkeSk ? headersNarkeSk : headersLocal;
      const columnWidths = useNarkeSk ? columnWidthsNarkeSk : columnWidthsLocal;

      XLSX.utils.sheet_add_aoa(ws, [headers], { origin: `A${currentRow}` });
      currentRow++;

         const levRow = Array(headers.length).fill('');
         levRow[0] = `Lev - ${day}`; // Add text only to the first cell
         const distRow = Array(headers.length).fill('');
         distRow[0] = distributorName; // Add text only to the first cell
         XLSX.utils.sheet_add_aoa(ws, [levRow], { origin: `A${currentRow}` });
         XLSX.utils.sheet_add_aoa(ws, [distRow], {
           origin: `A${currentRow + 1}`,
         });

         ws['!merges'] = ws['!merges'] || [];
         ws['!merges'].push({
           s: { r: currentRow - 1, c: 0 },
           e: { r: currentRow - 1, c: 7 },
         }); // Merge for Lev
         ws['!merges'].push({
           s: { r: currentRow, c: 0 },
           e: { r: currentRow, c: 7 },
         }); // Merge for Dist

         if (useNarkeSk) {
           ws['!merges'].push({
             s: { r: currentRow - 1, c: 9 },
             e: { r: currentRow, c: 15 },
           }); // Additional merge for H-P if useNarkeSk is true
         }
      
       currentRow += 2;



    // function calculatePalletsAndPPLFromDetails(order: any) {
    //   let backar60g = 0;
    //   let backar150g = 0;
    //   backar150g += order.mix150g + order.krisp150g;
    //   backar60g += order.mix60g + order.krisp60g;
      
    //   const equivalentBackar60g = backar150g * 2;

      
      
    //   const totalBackar60g = backar60g + equivalentBackar60g;

      
      
    //   let totalPallets = 0;
    //   if (totalBackar60g <= 20) {
    //     totalPallets = 0.5; 
        
    //   } else if (totalBackar60g <= 40) {
    //     totalPallets = 1;
    //   } else {
    //     totalPallets = Math.ceil(totalBackar60g / 40); 
        
    //   }

       
      
    //   let totalPPL = Math.ceil(totalPallets / 2);

    //   return { totalPallets, totalPPL };
    // }
      if(useNarkeSk) {
        // Add orders for this day
        orders.forEach((order: IExcelExportData) => {
        
            const totalBackar60gFormula = `C${currentRow}+D${currentRow}+(E${currentRow}+F${currentRow})*2`;
           
            const totalPalletsFormula = `IF(${totalBackar60gFormula}<=20,0.5,IF(${totalBackar60gFormula}<=40,1,CEILING(${totalBackar60gFormula}/40)))`;
            const totalPPLFormula = `CEILING(${totalPalletsFormula}/2)`;
 console.log(totalBackar60gFormula, totalPPLFormula, totalPalletsFormula)
          const orderData = [
            { t: 's', v: '' },
            { t: 's', v: order.storeName },
            { t: 'n', v: order.mix60g || 0 },
            { t: 'n', v: order.krisp60g || 0 },
            { t: 'n', v: order.mix150g || 0 },
            { t: 'n', v: order.krisp150g || 0 },
            { t: 's', v: '' },
            { t: 's', v: '' },
            { t: 'S', v: '' },
            { t: 'n', f: `SUM(C${currentRow}:F${currentRow})` },
            { t: 'n', f: totalPalletsFormula },
            { t: 'n', f: totalPPLFormula },
            { t: 's', v: order.address },
            { t: 's', v: order.postalCode ? order.postalCode : '' },
            { t: 's', v: order.city },
            { t: 's', v: '' },
          ];
          XLSX.utils.sheet_add_aoa(ws, [orderData], {
            origin: `A${currentRow}`,
          });
          currentRow++;
        });
      }else {
        // Add orders for this day
        orders.forEach((order: IExcelExportData) => {
          
          const orderData = [
            { t: 's', v: order.address },
            { t: 's', v: order.storeName },
            { t: 'n', v: order.mix60g || 0 },
            { t: 'n', v: order.krisp60g || 0 },
            { t: 'n', v: order.mix150g || 0 },
            { t: 'n', v: order.krisp150g || 0 },
            { t: 's', v: '' },
            { t: 's', v: '' },
          ];
          XLSX.utils.sheet_add_aoa(ws, [orderData], {
            origin: `A${currentRow}`,
          });
          currentRow++;
        });
      }
     

      if(useNarkeSk) {
        const totalsRow = [
          '',
          'Totalt',
          { f: `SUM(C${currentRow - orders.length}:C${currentRow - 1})` },
          { f: `SUM(D${currentRow - orders.length}:D${currentRow - 1})` },
          { f: `SUM(E${currentRow - orders.length}:E${currentRow - 1})` },
          { f: `SUM(F${currentRow - orders.length}:F${currentRow - 1})` },
        ];
        XLSX.utils.sheet_add_aoa(ws, [totalsRow], { origin: `A${currentRow}` });
         currentRow += 2; 
      }
      else {
         const totalsRow = [
           'Totalt',
           '',
           { f: `SUM(C${currentRow - orders.length}:C${currentRow - 1})` },
           { f: `SUM(D${currentRow - orders.length}:D${currentRow - 1})` },
           { f: `SUM(E${currentRow - orders.length}:E${currentRow - 1})` },
           { f: `SUM(F${currentRow - orders.length}:F${currentRow - 1})` },
         ];
         XLSX.utils.sheet_add_aoa(ws, [totalsRow], {
           origin: `A${currentRow}`,
         });
         currentRow += 2; 
      }
      

      
     

      ws['!cols'] = columnWidths;
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, 'file-name' + fileExtension);
    
     const confirmShipmentHandler = async () => {
        const orders = props.orders
        const IDs:number[] = []; 
        
        orders.forEach((order) => {
          order.orderDetails.forEach((orderDetail) => {
            IDs.push(orderDetail.orderId);})
        })
        const shipmentsToUpdate = {
          ids: IDs,
          values: {status: 1}
        }

       try {
         await updateShipmentsBulkAlt(shipmentsToUpdate);
         console.log('All shipments confirmed');
         props.onConfirm(); // Callback after successful update
       } catch (error) {
         console.error('Error confirming shipments:', error);
       }

        props.onConfirm();
      
     };

    if(props.confirmShipments) {
      confirmShipmentHandler();
    }
  };


  
  return (
    <Button
      color={'ghost'}
      label={props.label}
      onClick={() => exportToExcel()}
      short={true}
    />
  );
}


export default CreateExcelExport;