import { BACKEND_BASE_URL } from "../services/models";
import axios from "../shared/axios";
import { IApiResponse, ICar, ICarAssign, ICarInfo, ICarUnassign, ICarrier, ICarrierSchedules} from "../types/api";

const PREFIX = "/carrier";

export function listCarriers() {
  const url = `${BACKEND_BASE_URL}${PREFIX}/list?limit=20`;
  return axios.get<IApiResponse<{ data: ICarrier[] }>>(url).then((response) => { return response.data.payload;});
}

export function listCars() {
  const url = `${BACKEND_BASE_URL}${PREFIX}/car/list?limit=50`;
  return axios.get<IApiResponse<{ data: ICar[] }>>(url).then((response) => { return response.data.payload;});
}  

export function createCarrier(carrier: Partial<ICarrier>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/create`;
  return axios.post<IApiResponse<{ data: ICarrier }>>(url, carrier).then((response) => { return response.data.payload;});
}

export function getCar(id: number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/car/get/${id}`;
  return axios.get<IApiResponse<{ data: ICarInfo }>>(url).then((response) => { return response.data.payload;});
}


export function createCar(carInfo: Partial<ICar>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/car/create`;
  return axios.post<IApiResponse<{ data: ICar }>>(url, carInfo).then((response) => { return response.data.payload;});
}

export function assignCar(carInfo: Partial<ICarAssign>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/car/assign`;
  return axios.post<IApiResponse<{ data: ICar }>>(url, carInfo).then((response) => { return response.data.payload;});
}

export function unassignCar(carInfo: Partial<ICarUnassign>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/car/unassign`;
  return axios.post<IApiResponse<{ data: ICar }>>(url, carInfo).then((response) => { return response.data.payload;});
}

