import './EditCampaignPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import {
  ILocationAll,
} from '../../types/api';
import { IRetailer } from '../../types/api';
import { editCampaign, getCampaign} from '../../api/campaign';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';

interface IEditCampaignPopupProps {
  showPopup: boolean;
  onClose: () => void;
  campaignId: number;
  retailers: IRetailer[];
  onConfirm: () => void;
  locations: ILocationAll[];  
}

function EditCampaignPopup(props: IEditCampaignPopupProps) {
  console.log(props.campaignId)
  const [name, setName] = useState<string>('');
  const [productVariantId, setProductVariantId] = useState<number>(0);
  const [locationName, setLocationName] = useState<string>('');
  const [retailerName, setRetailerName] = useState<string>('');
  const [validFrom, setValidFrom] = useState<string>('');
  const [validTo, setValidTo] = useState<string>('');
  const [priceFixedSek, setPriceFixedSek] = useState<number | null>(null);
  const [priceOffSek, setPriceOffSek] = useState<number | null>(null);
  const [pricePercent, setPricePercent] = useState<number | null>(null);
  
  const areRequiredFieldsFilled = () => {
    return (
      name &&
      productVariantId &&
      validFrom &&
      validTo 
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'productVariantId':
        setProductVariantId(Number(value));
        break;
      case 'validFrom':
        setValidFrom(value);
        break;
      case 'validTo':
        setValidTo(value);
        break;
      case 'priceFixedSek':
        setPriceFixedSek(Number(value));
        break;
      case 'priceOffSek':
        setPriceOffSek(Number(value));
        break;
      case 'pricePercent':
        setPricePercent(Number(value));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
        const fetchAndSetCampaign = async () => {
            try {
            const response = await getCampaign(props.campaignId);
            const campaign = response.data;
           const validFrom = new Date(campaign.validFrom).toISOString().split('T')[0];
           const validTo = new Date(campaign.validTo).toISOString().split('T')[0];
            setName(campaign.name);
            setProductVariantId(campaign.productVariantId);
            setValidFrom(validFrom);
            setValidTo(validTo);
            setPriceFixedSek(campaign.priceFixedSek);
            setPriceOffSek(campaign.priceOffSek);
            setPricePercent(campaign.pricePercent);
            setRetailerName(props.retailers.find((retailer) => retailer.id === campaign.retailerId)?.name || '');
            setLocationName(props.locations.find((location) => location.id === campaign.locationId)?.name || '');
            } catch (error) {
                console.error('Error fetching campaign:', error);
            }
        }
        fetchAndSetCampaign();
  }, [props.campaignId]);
  async function  endCampaignHandler() {
     const validToTimestamp = Math.floor(new Date().getTime() / 1000);
     console.log(validToTimestamp)
     const campaignToEdit = {
       id: props.campaignId,
       validTo: validToTimestamp,
     };

     // Call createCampaign API function (to be implemented in your API logic)
     try {
       const response = await editCampaign(campaignToEdit);
       console.log('Campaign ended successfully:', response);
       props.onClose();
       props.onConfirm();
       // Handle post-creation logic (like reloading the page or updating state)
     } catch (error) {
       console.error('Error ending campaign:', error);
     }
  }
  
  async function editCampaignHandler() {
    const validFromTimestamp = Math.floor(new Date(validFrom).getTime() / 1000);
    const validToTimestamp = Math.floor(new Date(validTo).getTime() / 1000);
    const campaignToEdit = {
      id: props.campaignId,
      name,
      productVariantId,
      validFrom: validFromTimestamp,
      validTo: validToTimestamp,
        bulkGte: 0,
        bulkLte: 0,
      priceFixedSek,
      priceOffSek,
      pricePercent,
    };

    // Call createCampaign API function (to be implemented in your API logic)
    try {
      const response = await editCampaign(campaignToEdit);
      console.log('Campaign edited successfully:', response);
      props.onClose();
      props.onConfirm();
      // Handle post-creation logic (like reloading the page or updating state)
    } catch (error) {
      console.error('Error edited campaign:', error);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Redigera kampanj för {retailerName !== "" ? retailerName: locationName !== "" ? locationName : ""}: </span>}
    >
      <div className="edit-campaign-popup">
        <form
          className="edit-campaign-form"
          onSubmit={(e) => {
            e.preventDefault();
            editCampaignHandler();
          }}
        >
          <div className="campaign-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-campaign-name"
              placeholder="Namn"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="campaign-section">
            <span className="text-m-m">Startdatum</span>
            <input
              className="i-campaign-validFrom"
              placeholder="YYYY-MM-DD"
              name="validFrom"
              type="date"
              value={validFrom}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Slutdatum</span>
            <input
              className="i-campaign-validTo"
              placeholder="YYYY-MM-DD"
              name="validTo"
              type="date"
              value={validTo}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Produktvariant</span>
            <select
              className="i-campaign-productVariant"
              placeholder="Produktvariant"
              name="productVariantId"
              value={productVariantId}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj produktvariant
              </option>
              <option value="1">Krisp 60g</option>
              <option value="2">Krisp 150g</option>
              <option value="3">Mix 60g</option>
              <option value="4">Mix 150g</option>
            </select>
          </div>

          {/* 
          <div className="campaign-section">
            <span className="text-m-m">Bulk GTE</span>
            <input
              className="i-campaign-bulkGte"
              placeholder="Bulk GTE"
              name="bulkGte"
              type="number"
              value={bulkGte}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Bulk LTE</span>
            <input
              className="i-campaign-bulkLte"
              placeholder="Bulk LTE"
              name="bulkLte"
              type="number"
              value={bulkLte}
              onChange={handleInputChange}
            />
          </div> */}

          <div className="campaign-section">
            <span className="text-m-m">Fast pris i SEK</span>
            <input
              className="i-campaign-priceFixedSek"
              placeholder="Fast pris i SEK"
              name="priceFixedSek"
              type="number"
              value={priceFixedSek ? priceFixedSek : ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Prisavdrag i SEK</span>
            <input
              className="i-campaign-priceOffSek"
              placeholder="Prisavdrag i SEK"
              name="priceOffSek"
              type="number"
              value={priceOffSek ? priceOffSek : ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Prisprocent</span>
            <input
              className="i-campaign-pricePercent"
              placeholder="Prisprocent"
              name="pricePercent"
              type="number"
              value={pricePercent ? pricePercent : ''}
              onChange={handleInputChange}
            />
          </div>

          {/* Add form fields for each campaign attribute */}
          <div className="campaign-save-delete-wrapper">
            <button
              className="green-button campaign-save-button"
              type="submit"
              disabled={!areRequiredFieldsFilled()}
            >
              <span className="text-m-m">Uppdatera kampanj</span>
            </button>
            <DeleteButton
              label="Avsluta kampanj"
              icon="del-40"
              color="primary"
              onClick={endCampaignHandler}
              />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default EditCampaignPopup;

