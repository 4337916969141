import './EditSchedulePopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { deleteLocation, editLocation, getLocation } from '../../api/locations';
import {
  ILocInfoSchedules,
  ILocationInfo,
  IShipment,
  AggregatedOrder,
  OrderDetail,
  IEditShipmentBulk,
  IEditShipmentBulkAlt,
} from '../../types/api';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import Button from '../../components/UI/Button/Button';
import { predefinedVariants } from '../../shared/enums';
import AmountCard from '../../hoc/AmountCard/AmountCard';
import { changeShipmentStatus, removeShipmentFromSchedule, updateShipmentsBulkAlt } from '../../api/shipments';
import { formatDateToSwedish } from '../../shared/utility';
interface IEditSchedulePopupProps {
  showPopup: boolean;
  onClose: () => void;
  orders: AggregatedOrder[];
  onRemoveFromSchedule: () => void;
}

function EditSchedulePopup(props: IEditSchedulePopupProps) {
  const [name, setName] = useState<string>('');
  const [initialName, setInitialName] = useState<string>('');
  const [retailerId, setRetailerId] = useState<number>(0);
  const [initialRetailerId, setInitialRetailerId] = useState<number>(0);
  const [zipCode, setZipCode] = useState<string>('');
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [score, setScore] = useState<number | null>(null);
  const [scoreOffset, setScoreOffset] = useState<number | null>(null);
  const [customerOrderportalKey, setCustomerOrderportalKey] = useState<
    string | null
  >(null);
  const [fortnoxCustomerNumber, setFortnoxCustomerNumber] = useState<
    number | null
  >(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [createdAt, setCreatedAt] = useState<string>('');
  const [updatedAt, setUpdatedAt] = useState<string>('');
  const [campaign, setCampaign] = useState<any>([]);
  const [isCreatingLocation, setIsCreatingLocation] = useState<boolean>(false);
  const [carrierSchedules, setCarrierSchedules] = useState<ILocInfoSchedules[]>(
    []
  );
  const [contactPerson, setContactPerson] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const [carId, setCarId] = useState<number | null>(null);
  const [location, setLocation] = useState<ILocationInfo>();

  const [carrierId, setCarrierId] = useState<number>(0);
  const [brand, setBrand] = useState<string | undefined>('');

  const [loading, setLoading] = useState<boolean>(false);



 const [removedOrders, setRemovedOrders] = useState<OrderDetail[]>([]);

  
   const {weekday, dateAndMonth, hour} = formatDateToSwedish(new Date(props.orders[0].shipmentSchedule.carrierPickupEstimate));

  const handleCarrierScheduleChange = (index: number, carId: number) => {
    const updatedSchedules = [...carrierSchedules];
    updatedSchedules[index] = { ...updatedSchedules[index], id: carId };
    setCarrierSchedules(updatedSchedules);
  };

  const addCarrierSchedule = () => {
    setCarrierSchedules([
      ...carrierSchedules,
      { id: 20, name: '', pickupWeekDay: 0 },
    ]);
  };

  const removeCarrierSchedule = (index: number) => {
    const updatedSchedules = carrierSchedules.filter((_, idx) => idx !== index);
    setCarrierSchedules(updatedSchedules);
  };

  async function editScheduleHandler() {
    
  }

 

  
interface IOrderProductAmount {
  [variantTitle: string]: number;
}

interface IOrderProductState {
  orderId: number;
  products: IOrderProductAmount;
}

const [orderProductStates, setOrderProductStates] = useState<
  IOrderProductState[]
>([]);

useEffect(() => {
  const initialOrderProductStates = props.orders.map((order) => {
    const products: IOrderProductAmount = {};
    predefinedVariants.forEach((variant) => {
      const orderDetail = order.orderDetails.find(
        (detail) =>
          detail.productName === variant.product &&
          detail.productVariant === variant.productVariant
      );
      products[variant.title] = orderDetail ? orderDetail.amount : 0;
    });

    return {
      orderId: order.id,
      products,
    };
  });

  setOrderProductStates(initialOrderProductStates);
}, [props.orders]);

const handleAmountChange = (
  orderId: number,
  variantTitle: string,
  newAmount: number
) => {
  const updatedStates = orderProductStates.map((orderState) => {
    if (orderState.orderId === orderId) {
      return {
        ...orderState,
        products: {
          ...orderState.products,
          [variantTitle]: newAmount,
        },
      };
    }
    return orderState;
  });

  setOrderProductStates(updatedStates);
};



  async function handleScheduleRemove(orderDetails: OrderDetail[]) {
    if (window.confirm('Är du säker på att du vill ta bort från lastlistan?')) {
      try {
        const IDs:number[]= [];
        orderDetails.forEach((orderDetail) => {
          IDs.push(orderDetail.orderId);
        });

        const ordersToRemove:IEditShipmentBulkAlt = {
          ids: IDs,
          values: {
            status:5,
          }
        }
        const response = await updateShipmentsBulkAlt(ordersToRemove);
  
        setRemovedOrders(orderDetails);
        props.onClose();
        props.onRemoveFromSchedule();
        console.log("Removed successfully")
      } catch (error) {
        console.error('Error removing orders from shipment:', error);
      }
    }
  }

  if (loading && brand !== '') {
    return <div>Loading...</div>;
  } else {
  }
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Redigera lastlista</span>}
    >
      <div className="edit-schedule-popup">
        <form
          className="edit-schedule-form"
          onSubmit={(e) => {
            e.preventDefault();
            editScheduleHandler();
          }}
        >
          <div className="schedule-section">
            <div className="schedule-departure-section">
             Lastdag: {weekday}, {dateAndMonth} - {hour}
            </div>
          </div>
          {props.orders.map((order, index) => {
            if(removedOrders.find((removedOrder) => removedOrder.orderId === order.id)){
                return;
            }else {
              return (
                <div key={index} className="schedule-section">
                  <div className="schedule-location-section">
                    <div className="schedule-order-location">
                      {order.locationName}
                    </div>

                    <div className="schedule-amount-section">
                      {predefinedVariants.map((variant, index) => {
                        const getAmountForVariant = (
                          productVariant: string,
                          productName: string
                        ) => {
                          const found = order.orderDetails.find(
                            (orderDetail) =>
                              orderDetail.productVariant === productVariant &&
                              orderDetail.productName === productName
                          );
                          return found ? found.amount : 0;
                        };

                        return (
                          <AmountCard
                            key={index}
                            index={index}
                            variant={variant}
                            getAmountForVariant={getAmountForVariant}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="remove-from-schedule-wrapper">
                    <Button
                      onClick={() => handleScheduleRemove(order.orderDetails)}
                      color={'secondary'}
                      label={'Ta bort från lastlista'}
                      short={true}
                    />
                  </div>
                </div>
              );
            }
          }

            
          )}

          
        </form>
      </div>
    </Popup>
  );
}

export default EditSchedulePopup;


