import { useEffect, useState } from 'react';
import './Forecast.scss';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import SettingsOdlingPopup from '../../containers/SettingsOdlingPopup/SettingsOdlingPopup';
import SettingsProductionPopup from '../../containers/SettingsProductionPopup/SettingsProductionPopup';
import SettingsFundementalPopup from '../../containers/SettingsFundementalPopup/SettingsFundementalPopup';


interface IForecastProps {}

function Forecast(props: IForecastProps) {
  const [odlingSettingsPopupOpen, setOdlingSettingsPopupOpen] = useState<boolean>(false);
  const [productionSettingsPopupOpen, setProductionSettingsPopupOpen] = useState<boolean>(false);
  const [fundementalSettingsPopupOpen, setFundementalSettingsPopupOpen] = useState<boolean>(false);

  function openOdlingSettingsPopup() {
    setOdlingSettingsPopupOpen(true);
  }
  function openProductionSettingsPopup() {
    setProductionSettingsPopupOpen(true);
  }
  function openFundementalSettingsPopup() {
    setFundementalSettingsPopupOpen(true);
  }


  return (
    <div className="forecast-page-wrapper">
      <div className="forecast-settings-wrapper">
        <div className="forecast-setting-buttons-wrapper">
          <CreateButton
            color={'primary'}
            label={'Odlings inställingar'}
            icon={'plus-30'}
            onClick={openOdlingSettingsPopup}
          />
          <CreateButton
            color={'primary'}
            label={'Productions inställningar'}
            icon={'plus-30'}
            onClick={openProductionSettingsPopup}
          />
          <CreateButton
            color={'primary'}
            label={'Grundinställningar'}
            icon={'plus-30'}
            onClick={openFundementalSettingsPopup}
          />
        </div>
      </div>

      <SettingsOdlingPopup
        showPopup={odlingSettingsPopupOpen}
        onClose={() => setOdlingSettingsPopupOpen(false)}
      />
      <SettingsProductionPopup
        showPopup={productionSettingsPopupOpen}
        onClose={() => setProductionSettingsPopupOpen(false)}
      />
      <SettingsFundementalPopup
        showPopup={fundementalSettingsPopupOpen}
        onClose={() => setFundementalSettingsPopupOpen(false)}
      />
    </div>
  );
}

export default Forecast;
