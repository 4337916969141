import './CreateButton.scss';
import { IconType20, IconType30, IconType40 } from '../../../types/internal';
import Spinner from '../Spinner/Spinner';

interface ButtonProps {
  onClick?: () => void;
  color: 'secondary' | 'primary';
  label: string;
  className?: string;
  width?: string;
  isLoading?: boolean;
  icon?: IconType20 | IconType30 | IconType40;
  short?: boolean;
  disabled?: boolean;
  dontSubmit?: boolean;
}

function Button(props: ButtonProps) {
  const buttonClasses = ['create-button', 'text-m-m', props.color];
  if (props.className) {
    buttonClasses.push(props.className);
  }
  if (props.isLoading) {
    buttonClasses.push('loading');
  }

  if (props.disabled) {
    buttonClasses.push('disabled');
  }
  if (!props.disabled) {
    buttonClasses.push('waves-effect');
  }

  let icon: JSX.Element | undefined;
  switch (props.icon) {
    case 'plus-20':
      icon = (
        <div className="create-button-cricle-20">
          <i className="fa-reggular fa-plus"></i>
        </div>
      );
      break;
    case 'plus-30':
      icon = (
        <div className="create-button-cricle-30">
          <i className="fa-reggular fa-plus"></i>
        </div>
      );
      break;
    case 'plus-40':
      icon = (
        <div className="create-button-cricle-30">
          <i className="fa-reggular fa-plus"></i>
        </div>
      );
      break;
  }

  if (icon) {
    buttonClasses.push('icon-button');
  }

  return (
    <button
      className={buttonClasses.join(' ')}
      style={{ width: props.width, height: props.short ? 20 : 30 }}
      onClick={props.disabled ? undefined : props.onClick}
      type={props.dontSubmit ? 'button' : 'submit'}
    >
      {props.isLoading ? (
        <Spinner />
      ) : icon ? (
        <div className="create-button-content">
          {icon}
          {props.label}
        </div>
      ) : (
        props.label
      )}
    </button>
  );
}

export default Button;
