import { useEffect, useRef, useState } from 'react';
import './Locations.scss';
import {
  listLocations,
  getLocation,
  listAllLocations,
  listLocationsDetailed,
} from '../../api/locations';
import { listRetailers } from '../../api/retailers';
import { listCampaigns, getCampaign } from '../../api/campaign';
import {
  ILocationInfo,
  ILocation,
  IRetailer,
  ICampaign,
  ICampaignInfo,
  ICarrier,
  ICar,
  ILocationAll,
  ILocationsListDetailed,
  ILocDetailedSchedules,
} from '../../types/api';
import CreateLocationPopup from '../../containers/CreateLocationPopup/CreateLocationPopup';
import EditLocationPopup from '../../containers/EditLocationPopup/EditLocationPopup';
import Button from '../../components/UI/Button/Button';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import { listCarriers, listCars } from '../../api/carriersAndCars';
import { useClickOutside } from '../../shared/hooks/useClickOutside';
import CreateCampaignPopup from '../../containers/CreateCampaignPopup/CreateCampaignPopup';
import CreateCarrierPopup from '../../containers/CreateCarrierPopup/CreateCarrierPopup';

import CreateCarPopup from '../../containers/CreateCarPopup/CreateCarPopup';
import EditCampaignPopup from '../../containers/EditCampaignPopup/EditCampaignPopup';
import { use } from 'i18next';
import { formatDateToSwedish } from '../../shared/utility';
import Spinner from '../../components/UI/Spinner/Spinner';
import CreateRetailerPopup from '../../containers/CreateRetailerPopup/CreateRetailerPopup';
interface ILocationsProps {}

function Locations(props: ILocationsProps) {

  const [locationsWithInfo, setLocationsWithInfo] = useState<ILocationInfo[]>(
    []
  );
  const [locationsDetailed, setLocationsDetailed] = useState<ILocationsListDetailed[]>([]);
  const [displayCount, setDisplayCount] = useState(50);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [allLocations, setAllLocations] = useState<ILocationAll[]>([]);
  const [lastFetchedIndex, setLastFetchedIndex] = useState(0);
  const isFirstRender = useRef(true);

  const [latestOrders, setLatestOrders] = useState<{ [key: number]: any }>({});
  const [locations, setLocations] = useState<ILocation[]>([]);

  const [searchResults, setSearchResults] = useState<ILocationsListDetailed[]>([]);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [retailers, setRetailers] = useState<IRetailer[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [campaignsFinal, setCampaignsWithInfo] = useState<ICampaignInfo[]>([]);
  const [cars, setCars] = useState<ICar[]>([]);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [showCreateCampaignPopup, setShowCreateCampaignPopup] =
    useState<boolean>(false);
  const [showCreateCarrierPopup, setShowCreateCarrierPopup] =
    useState<boolean>(false);
  const [showCreateLocationPopup, setShowCreateLocationPopup] =
    useState<boolean>(false);
  const [showEditLocationPopup, setShowEditLocationPopup] =
    useState<boolean>(false);
  const [showCreateCarPopup, setShowCreateCarPopup] = useState<boolean>(false);
  const [showEditCampaignPopup, setShowEditCampaignPopup] =
    useState<boolean>(false);
  const [showCreateRetailerPopup, setShowCreateRetailerPopup] =
    useState<boolean>(false);

  const [loadingExtraInfo, setLoadingExtraInfo] = useState<boolean>(false);

  const [showEditCarrierPopup, setShowEditCarrierPopup] =
    useState<boolean>(false);
  const [currentEditLocationId, setCurrentEditLocationId] = useState<
    number | null
  >(null);
  const [currentEditCampaignId, setCurrentEditCampaignId] = useState<
    number | null
  >(null);
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const [searchString, setSearchString] = useState<string>('');
  const [loadingMore, setLoadingMore] = useState<boolean>(false); 


  async function fetchAndSetLocationDetails(
    displayedLocations: ILocationsListDetailed[],
    delayTime: number
  ) {
    setLoadingExtraInfo(true);

    const newLocations = displayedLocations.slice(lastFetchedIndex);

    for (const location of newLocations) {
      await delay(delayTime);
      getLocation(location.id)
        .then((response) => {
          const locationInfo = response.data;

          locationInfo.id = location.id;

          setLocationsWithInfo((prevLocations) => {
            const existingLocation = prevLocations.find(
              (l) => l.id === locationInfo.id
            );
            if (existingLocation) {
              return prevLocations.map((l) =>
                l.id === locationInfo.id ? locationInfo : l
              );
            } else {
              return [...prevLocations, locationInfo];
            }
          });
        })
        .catch((error) => {
          console.error('Error fetching location details:', error);
        });
    }

    setLoadingExtraInfo(false);
    setLastFetchedIndex(displayedLocations.length);
  }

  async function fetchCampaginsWithDelay(campagins: ICampaign[]) {
    const campaginsWithInfo: ICampaignInfo[] = [];

    for (const campagin of campagins) {
      await delay(300);
      const response = await getCampaign(campagin.id);
      const campaginInfo = response.data;
      campaginsWithInfo.push(campaginInfo);
    }
    return campaginsWithInfo;
  }

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const response = await listLocations();

      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllLocations = async () => {
    setLoading(true);
    try {
      await delay(500);
      const response = await listAllLocations();

      setAllLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLocationsDetailed = async () => {
    setLoading(true);
    try {
      const response = await listLocationsDetailed();
      setLocationsDetailed(response.data);
      setHasMore(response.hasNextPage)
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);
    }
  }



  async function fetchCars() {
    setLoading(true);
    try {
      const carsResponse = await listCars();
      setCars(carsResponse.data);
    } catch (error) {
      console.error('Error fetching cars:', error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const retailersResponse = await listRetailers();
        setRetailers(retailersResponse.data);

        const carsResponse = await listCars();
        setCars(carsResponse.data);

        const carriersResponse = await listCarriers();
        setCarriers(carriersResponse.data);

        const campaignsResponse = await listCampaigns();
        setCampaigns(campaignsResponse.data);

        // const campaignsWithInfo = await fetchCampaginsWithDelay(
        //   campaignsResponse.data
        // );
        // setCampaignsWithInfo(campaignsWithInfo);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllLocations();
    fetchLocationsDetailed();
    fetchData();
   
  }, []);
  


  



  useEffect(() => {
    const locationsToDisplay = isSearchActive ? searchResults : locationsDetailed;

    const displayedLocations = locationsToDisplay.slice(0, displayCount);
    if (lastFetchedIndex < displayedLocations.length) {
      
    }
  }, [displayCount, locationsDetailed, searchResults, isSearchActive]);

  const searchLocation = async () => {
    const filteredLocations = locationsDetailed.filter((location) =>
      location.locationName.toLowerCase().includes(searchString.toLowerCase())
    );

    setSearchResults(filteredLocations);
    if (searchString.length > 0) {
      setIsSearchActive(true);
      setDisplayCount(10);
    } else {
      setDisplayCount(10);
      setIsSearchActive(false);
    }
  };

  useEffect(() => {
    if (isSearchActive) {
      const activeLocations = locationsDetailed.filter((location) =>
        location.locationName.toLowerCase().includes(searchString.toLowerCase())
      );
      fetchAndSetLocationDetails(activeLocations, 300);
      setSearchResults(activeLocations);
    }
  }, [allLocations, isSearchActive]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const openEditCampaign = (campaignId: number) => {
    setCurrentEditCampaignId(campaignId); 
    setShowEditCampaignPopup(true);
  };
  const openCreateCampaign = () => {
    setShowCreateCampaignPopup(true);
  };
  const openCreateCarrier = () => {
    setShowCreateCarrierPopup(true);
  };
  const openCreateLocation = () => {
    setShowCreateLocationPopup(true);
  };
  const openCreateCar = () => {
    setShowCreateCarPopup(true);
  };
  const openCreateRetailer = () => {
    setShowCreateRetailerPopup(true);
  }
  const openEditLocation = (locationId: number) => {
    setCurrentEditLocationId(locationId);
    setShowEditLocationPopup(true);
  };

   const [currentPage, setCurrentPage] = useState(1);



   const loadMore = async () => {
     try {
       setLoadingMore(true);
       setCurrentPage((prevPage) => prevPage + 1); 
       const newPage = currentPage + 1;
       const response = await listLocationsDetailed(newPage)
       setLocationsDetailed((prevLocations) => [
         ...prevLocations,
         ...response.data,
       ]); 
       setHasMore(response.hasNextPage); 
       setDisplayCount((prevCount) => prevCount + 50);
     } catch (error) {
       console.error('Error fetching more locations:', error);
     } finally {
       setLoadingMore(false);
     }
   };

  const locationsToDisplay = isSearchActive ? searchResults : locationsDetailed;
  const displayedLocations = locationsToDisplay.slice(0, displayCount);

  function renderCarrierSchedules(carrierSchedules: ILocDetailedSchedules[]) {
    return (
      <ul>
        {carrierSchedules.map((schedule, index) => (
          <li key={index}>
            {cars.find((car) => car.id === schedule.carrierCarId)?.name || '-'}
          </li>
        ))}
      </ul>
    );
  }

  if (loading) {
    return <Spinner />;
  } else {

  }
  
  return (
    <div className="locations-page-wrapper">
      <div className="locations-actions-wrapper">
        <div className="locations-create-wrapper">
          <CreateButton
            onClick={openCreateLocation}
            color={'primary'}
            label={'Ny Butik'}
            icon={'plus-20'}
            short={true}
          />
          <CreateButton
            onClick={openCreateCampaign}
            color={'primary'}
            label={'Ny Kampanj'}
            icon={'plus-20'}
            short={true}
          />
          <CreateButton
            onClick={openCreateRetailer}
            color={'primary'}
            label={'Ny retailer'}
            icon={'plus-20'}
            short={true}
          />
          <CreateButton
            onClick={openCreateCarrier}
            color={'primary'}
            label={'Ny Distributör'}
            icon={'plus-20'}
            short={true}
          />
          <CreateButton
            onClick={openCreateCar}
            color={'primary'}
            label={'Ny Bil'}
            icon={'plus-20'}
            short={true}
          />
        </div>
        <div className="locations-search-wrapper">
          <input
            className="location-search-input"
            value={searchString}
            onChange={handleInputChange}
            placeholder="Sök efter butik"
          ></input>
          <button
            onClick={searchLocation}
            className="green-button location-search-button"
            type="submit"
          >
            <span className="text-m-m">Sök</span>
          </button>
        </div>
      </div>
      <table className="locations-table">
        <thead>
          <tr>
            <th>Namn</th>
            <th>Retailer</th>
            <th>Varumärke</th>
            <th>Address</th>
            <th>Distributör</th>
            <th>Senaste order</th>
            <th>Kampanj</th>
          </tr>
        </thead>
        <tbody>
          {/* {displayedLocations.map((location) => {
            const locationInfo = locationsWithInfo.find(
              (info) => info.id === location.id
            );

            const campaignInfo = campaignsFinal?.find(
              (campaign) => campaign.retailerId === locationInfo?.retailerId
            );

            return (
              <tr key={location.id}>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.name}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {(locationInfo &&
                    retailers.find(
                      (retailer) => retailer.id === locationInfo.retailerId
                    )?.name) ||
                    '-'}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {(locationInfo &&
                    retailers.find(
                      (retailer) => retailer.id === locationInfo.retailerId
                    )?.brand) ||
                    '-'}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {locationInfo
                    ? `${locationInfo.address1} ${locationInfo.address2 || ''}`
                    : '-'}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {locationInfo
                    ? renderCarrierSchedules(locationInfo.carrierSchedules)
                    : '-'}
                </td>
                <td>
                 
                </td>
                <td
                  onClick={() =>
                    openEditCampaign(campaignInfo ? campaignInfo.id : 0)
                  }
                >
                  {campaignsFinal?.find(
                    (campaign) =>
                      campaign.retailerId === locationInfo?.retailerId
                  )
                    ? campaignsFinal?.find(
                        (campaign) =>
                          campaign.retailerId === locationInfo?.retailerId
                      )?.name
                    : '-'}
                 
                </td>
              </tr>
            );
          })} */}

          {displayedLocations.map((location) => {
            let { weekday, hour, dateAndMonth } = {
              weekday: '',
              hour: '',
              dateAndMonth: '',
            };
            let stringDate = '';

            if (location.lastOrder.shipmentCreatedAt) {
              const formattedDate = formatDateToSwedish(
                new Date(location.lastOrder.shipmentCreatedAt)
              );
              weekday = formattedDate.weekday;
              hour = formattedDate.hour;
              dateAndMonth = formattedDate.dateAndMonth;

              stringDate = `${weekday}, ${dateAndMonth} `;
            }

            return (
              <tr key={location.id}>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.locationName}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.retailerName}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.retailerBrand}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.address}
                </td>
                <td onClick={() => openEditLocation(location.id)}>
                  {location.carrierSchedules
                    ? renderCarrierSchedules(location.carrierSchedules)
                    : '-'}
                </td>
                <td>{stringDate}</td>
                {location.campaigns.length > 0 ? (
                  <td
                    onClick={() => openEditCampaign(location.campaigns[0].campaignId)}
                  >
                    {location.campaigns[0].name}
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {hasMore ? (
        !loadingMore ? (
          <div className="load-more-wrapper">
            <Button
              onClick={loadMore}
              color={'secondary'}
              label={'Load more'}
            />
          </div>
        ) : (
          <Spinner />
        )
      ) : null}
      {showEditLocationPopup && currentEditLocationId !== null && (
        <EditLocationPopup
          showPopup={showEditLocationPopup}
          onClose={() => setShowEditLocationPopup(false)}
          locationId={currentEditLocationId}
          cars={cars}
          retailers={retailers}
          campaigns={campaigns}
          onChange={fetchLocationsDetailed}
        />
      )}
      <CreateLocationPopup
        showPopup={showCreateLocationPopup}
        onClose={() => setShowCreateLocationPopup(false)}
        cars={cars}
        retailers={retailers}
      />
      <CreateCampaignPopup
        showPopup={showCreateCampaignPopup}
        onClose={() => setShowCreateCampaignPopup(false)}
        retailers={retailers}
        allLocations={allLocations}
      />
      <CreateCarrierPopup
        showPopup={showCreateCarrierPopup}
        onClose={() => setShowCreateCarrierPopup(false)}
        carriers={carriers}
      />
      <CreateCarPopup
        showPopup={showCreateCarPopup}
        onClose={() => setShowCreateCarPopup(false)}
        cars={cars}
        carriers={carriers}
        onConfirm={fetchCars}
      />
      <CreateRetailerPopup
        showPopup={showCreateRetailerPopup}
        onClose={() => setShowCreateRetailerPopup(false)}
        retailers={retailers}
      />
      {currentEditCampaignId !== null && (
        <EditCampaignPopup
          showPopup={showEditCampaignPopup}
          onClose={() => setShowEditCampaignPopup(false)}
          campaignId={currentEditCampaignId}
          retailers={retailers}
          onConfirm={fetchLocationsDetailed}
          locations={allLocations}
        />
      )}
    </div>
  );
}

export default Locations;
