import Button from '../UI/Button/Button';
import './OrderCard.scss';
import { AggregatedOrder, ICar, ICarrier, IEditShipmentBulkAlt } from '../../types/api';
import { useEffect, useState } from 'react';
import { set } from 'date-fns';
import EditOrderPopup from '../../containers/EditOrderPopup/EditOrderPopup';
import AmountCard from '../../hoc/AmountCard/AmountCard';
import { changeShipmentStatus, updateShipmentsBulkAlt } from '../../api/shipments';
import { predefinedVariants } from '../../shared/enums';
import { formatDateToSwedish } from '../../shared/utility';
import { current } from '@reduxjs/toolkit';

interface IOrderCardProps {
  order: AggregatedOrder;
  currentFilterDate: Date;
  cars: ICar[];
  onConfirm: () => void;
}
function OrderCard(props: IOrderCardProps) {
  console.log(props.order, 'filterday:', props.currentFilterDate);
  const [showEditOrderPopup, setShowEditOrderPopup] = useState<boolean>(false);
  const shipmentCreatedAt = new Date(props.order.shipmentCreatedAt);

  const date = new Date(shipmentCreatedAt);
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(date);

  
  const getAmountForVariant = (productVariant: string, productName: string) => {
    const found = props.order.orderDetails.find(
      (orderDetail) =>
        orderDetail.productVariant === productVariant &&
        orderDetail.productName === productName
    );
    return found ? found.amount : 0;
  };
  useEffect(() => {
    
  }, [props.order])
  
  const openEditOrder = () => {
    setShowEditOrderPopup(true);
  };
 const confirmShipmentHandler = async() => {
  const IDs: number[] = [];
  props.order.orderDetails.map((order) => IDs.push(order.orderId));
  const ordersToConfirm: IEditShipmentBulkAlt ={
    ids: IDs,
    values: {
      status: 0,
    },
  } 
  try  {
    const response = await updateShipmentsBulkAlt(ordersToConfirm);
    props.onConfirm();
  } catch (error) {
    console.log("Error confirming shipments: ",error)
  }
   
 };


 if(!props.order) {
  return null
 }
 else {
  
 }
  return (
    <div className="order-card">
      <div className="order-card-top">
        <div className="order-card-top-left">
          <span className="text-l-s">{props.order.locationName}</span>
          <div className="order-carrier">
            <i className="far fa-link text-color-gray" />
            <span className="text-m-m">
              Orderdatum: {weekday}, {dateAndMonth}
            </span>
          </div>
          <span>Bil: {props.order.carName}</span>

        </div>
        <div className="order-card-top-right">
          <button className="edit-order-button" onClick={openEditOrder}>
            <i className="far fa-edit"></i>
          </button>
          <button
            className="add-order-to-list-button"
            onClick={confirmShipmentHandler}
          >
            <i className="far fa-check"></i>
          </button>
        </div>
      </div>
      <div className="order-card-bottom">
        {predefinedVariants.map((variant, index) => (
          <AmountCard
            key={index}
            index={index}
            variant={variant}
            getAmountForVariant={getAmountForVariant}
          />
        ))}
      </div>
      <EditOrderPopup
        showPopup={showEditOrderPopup}
        onClose={() => setShowEditOrderPopup(false)}
        locationName={props.order.locationName}
        cars={props.cars}
        order={props.order}
        onConfirm={props.onConfirm}
      />
    </div>
  );
}

export default OrderCard;
