import './CreateLocationPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { createLocation } from '../../api/locations';
import {
  ICar,
  ICarrier,
  ILocInfoSchedules,
  ILocationInfo,
  IRetailer,
} from '../../types/api';
import { listRetailers } from '../../api/retailers';
import { assignCar, listCarriers } from '../../api/carriersAndCars';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';
import CreateButton from '../../components/UI/CreateButton/CreateButton';

interface ICreateLocationPopupProps {
  showPopup: boolean;
  onClose: () => void;
  cars: ICar[];
  retailers: IRetailer[];
}

function CreateLocationPopup(props: ICreateLocationPopupProps) {
  const [name, setName] = useState<string>('');
  const [retailerId, setRetailerId] = useState<number>();
  const [zipCode, setZipCode] = useState<string>('');
  const [carrierId, setCarrierId] = useState<number | null>(null);
  const [brand, setBrand] = useState<number | null>(null);
  const [address, setAddress] = useState<string>('');
  const [priority, setPriority] = useState<number | null>(null);
  const [distributor, setDistributor] = useState<number | null>(null);
  const [contactPerson, setContactPerson] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [carrierSchedules, setCarrierSchedules] = useState<ILocInfoSchedules[]>(
    []
  );

  const areRequiredFieldsFilled = () => {
    return name && retailerId && zipCode && address;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'retailerId':
        setRetailerId(Number(value));
        break;
      case 'zipcode':
        setZipCode(value);
        break;
      case 'brand':
        setBrand(Number(value));
        break;
      case 'address':
        setAddress(value);
        break;
      case 'priority':
        setPriority(Number(value));
        break;
      case 'distributor':
        setDistributor(Number(value));
        break;
      case 'contactPerson':
        setContactPerson(value);
        break;
      case 'telephone':
        setTelephone(value);
        break;
      case 'comment':
        setComment(value);
        break;
      default:
        break;
    }
  };

  const handleCarrierScheduleChange = (index: number, carId: number) => {
    const updatedSchedules = [...carrierSchedules];
    updatedSchedules[index] = { ...updatedSchedules[index], id: carId };
    setCarrierSchedules(updatedSchedules);
  };

  const addCarrierSchedule = () => {
    setCarrierSchedules([
      ...carrierSchedules,
      { id: 20, name: '', pickupWeekDay: 0 },
    ]);
  };

  const removeCarrierSchedule = (index: number) => {
    const updatedSchedules = carrierSchedules.filter((_, idx) => idx !== index);
    setCarrierSchedules(updatedSchedules);
  };

  async function createLocationHandler() {
    if (!retailerId || !name || !address) {
      alert('Missing required fields');
      return;
    }
    const locationToCreate: Partial<ILocationInfo> = {
      retailerId: retailerId,
      name: name,
      zipcode: zipCode,
      address1: address,
      address2: '',
      city: '',
      country: '',
      score: priority || 0,
      scoreOffset: 0,
      customerOrderportalKey: '',
      fortnoxCustomerNumber: 0,
      longitude: 0,
      latitude: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      const response = await createLocation(locationToCreate);
      console.log('Location created successfully:', response);

      for (const carrierSchedule of carrierSchedules) {
        const car = props.cars.find((car) => car.id === carrierSchedule.id);
        try {
          const carToAssign = {
            locationId: response.data.id,
            carId: car?.id,
            pickupWeekDay: car?.pickupDay,
            deliveryWeekDay: 0,
          };
          await assignCar(carToAssign);
          console.log(`Car ${car?.id} assigned successfully to location`);
        } catch (error) {
          console.error('Error assigning car to location:', error);
        }
      }
      props.onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error creating location:', error);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Lägg till ny butik</span>}
    >
      <div className="create-location-popup">
        <form
          className="create-location-form"
          onSubmit={(e) => {
            e.preventDefault();
            createLocationHandler();
          }}
        >
          <div className="location-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-location-name"
              placeholder="Namn"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Retailer</span>
            <select
              className="s-location-retailer"
              name="retailerId"
              value={retailerId || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj retailer
              </option>
              {props.retailers.map((retailer) => (
                <option key={retailer.id} value={retailer.id}>
                  {retailer.name}
                </option>
              ))}
            </select>
          </div>
          <div className="location-section">
            <span className="text-m-m">Varumärke</span>
            <select
              className="s-location-varu"
              name="brand"
              value={brand || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj varumärke
              </option>
              {props.retailers.map((retailer) => (
                <option key={retailer.id} value={retailer.id}>
                  {retailer.brand}
                </option>
              ))}
            </select>
          </div>
          <div className="location-section">
            <span className="text-m-m">Adress</span>
            <input
              className="i-location-address"
              placeholder="Adress"
              name="address"
              value={address}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Postnummer</span>
            <input
              className="i-location-zipcode"
              placeholder="Postnummer"
              name="zipcode"
              value={zipCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Prioritet</span>
            <select
              className="s-location-priority"
              name="priority"
              value={priority || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj prioritet
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          {carrierSchedules.map((schedule, index) => (
            <div key={index} className="location-section">
              <span className="text-m-m">Val av bil {index + 1}</span>
              <select
                className="s-location-carrier"
                value={schedule.id || ''}
                onChange={(e) =>
                  handleCarrierScheduleChange(index, Number(e.target.value))
                }
              >
                <option value="" disabled>
                  Välj bil
                </option>
                {props.cars.map((car) => (
                  <option key={car.id} value={car.id}>
                    {car.name}
                  </option>
                ))}
              </select>
              <DeleteButton
                color={'primary'}
                label={'Ta bort bil'}
                icon={'del-40'}
                onClick={() => removeCarrierSchedule(index)}
                className="popup-btn"
              />
            </div>
          ))}
          <CreateButton
            color={'primary'}
            label={'Lägg till bil'}
            icon={'plus-30'}
            onClick={() => addCarrierSchedule()}
            dontSubmit={true}
            className="popup-btn"
          />

          <div className="location-section">
            <span className="text-m-m">Kontaktperson</span>
            <input
              className="i-location-contactperson"
              placeholder="Namn"
              name="contactPerson"
              value={contactPerson}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Telefonnummer</span>
            <input
              className="i-location-telephone"
              placeholder="Tel-nr"
              name="telephone"
              value={telephone}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Kommentar</span>
            <input
              className="i-location-comment"
              placeholder="Kommentar"
              name="comment"
              value={comment}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-save-wrapper">
            <button
              className="green-button location-save-button"
              type="submit"
              disabled={!areRequiredFieldsFilled()}
            >
              <span className="text-m-m">Skapa butik</span>
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateLocationPopup;
