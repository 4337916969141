import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import { checkAuth } from './services/backend/auth';
import Sales from './pages/Sales/Sales';
import Login from './components/Login';
import Home from './pages/Home/Home';
import AzureCallbackHandler from './components/AzureCallbackHandler';
import Forecast from './pages/Forecast/Forecast';
import {ROUTE} from './shared/enums';
import Communication from './pages/Communication/Communication';
import Locations from './pages/Locations/Locations';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authStatus = await checkAuth();
      setIsAuthenticated(authStatus);
    };
    checkAuthentication();
  }, []);
  
  if (isAuthenticated) {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path={'/'} exact>
              <Home />
            </Route>
            <Route path={ROUTE.Sales} exact>
              <Sales />
            </Route>
            <Route path={ROUTE.Locations} exact >
              <Locations />
            </Route>
            <Route path={ROUTE.Communication} exact>
              <Communication />
            </Route>
            <Route path={ROUTE.Forecast} exact>
              <Forecast />
            </Route>
          </Switch>
        </Layout>
      </div>
    );
  } else {
    return (
      <div>
        <Switch>
          <Route path={'/'} exact>
            <Login />
          </Route>
          <Route path={'/auth/azuread/callback'}>
            <AzureCallbackHandler />
          </Route>
        </Switch>
      </div>
    );
  }
};

export default App;
