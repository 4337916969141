import "./TotalOrdersCard.scss";
import { AggregatedOrder } from "../../types/api";
import { useState, useEffect } from 'react';
import { predefinedVariants } from "../../shared/enums";


interface ITotalOrdersCardProps {
  allOrders: AggregatedOrder[]; // Array of all order objects
}
interface TotalByVariant {
  [key: string]: number; // This means any string key will have a number value
}
function TotalOrdersCard(props: ITotalOrdersCardProps) {
    
    const [totalByVariant, setTotalByVariant] = useState<TotalByVariant>({});
    const [overallTotal, setOverallTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [ordersAmount, setOrdersAmount] = useState(0);
    


  function calculateTotalSaladAmounts(
    productName: string,
    variantName: string
  ) {
    let totalAmount = 0;
    props.allOrders.forEach((aggregatedOrder) => {
      if(aggregatedOrder.shipmentStatus !== 5) return;
      aggregatedOrder.orderDetails.forEach((orderDetail) => {
        if (
          orderDetail.productName === productName &&
          orderDetail.productVariant=== variantName
        ) {
          totalAmount += orderDetail.amount;
        }
      });
    });
    return totalAmount;
  }

   useEffect(() => {
  const totals: TotalByVariant = predefinedVariants
    .reduce((acc, variant) => {
      const productName = variant.product;
      const variantName = variant.productVariant;
      acc[variant.title] = calculateTotalSaladAmounts(productName, variantName);
      return acc;
    }, {} as TotalByVariant);
    setTotalByVariant(totals);

    const totalAmount = Object.values(totals).reduce(
        (sum, amount) => sum + amount,
        0
    );
    setOverallTotal(totalAmount);

   let amount = 0;
   const setTotalOrders = () => {
     props.allOrders.forEach((order) => {
       if (order.shipmentStatus !== 0) return;
       amount++;
     });
     setOrdersAmount(amount);
   };
   setTotalOrders();

     setLoading(false)
   }, [props.allOrders]);


  
if (loading) {
  return <div>Loading...</div>; // Or any other placeholder content
}

  return (
    <div className="total-order-card">
      <div className="total-order-card-top">
        <div className="total-order-card-top-left">
          <span className="text-l-s">Försäljning backar</span>
          <div className="total-orders-amount-wrapper">
            <div className="total-orders-amount text-m-l">
              {overallTotal} st
            </div>
            <div className="text-m-l text-color-gray">/</div>
            <div className="forecast-orders-amount text-m-l text-color-gray">
              900 st
            </div>
          </div>
        </div>
        <div className="total-order-card-top-right">
          {ordersAmount === 1 ? ordersAmount + " order" : ordersAmount + " ordrar"} 
        </div>
      </div>
      <div className="total-order-card-bottom">
        {predefinedVariants.map((variant) => (
          <div key={variant.id} className="salad-dot-wrapper-in-card">
            <div className={`dot ${variant.class}`} />
            <div className="amount-salad-text">
              <div className="text-m-m">
                {`${totalByVariant[variant.title] || 0}`}
              </div>
              <div className="text-m-m text-color-gray">/</div>
              <div className="text-m-m text-color-gray">25</div>{' '}
              {/* Adjust this number as needed */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TotalOrdersCard;
