import { BACKEND_BASE_URL } from "../models";

export const checkAuth = async () => {
  const token = localStorage.getItem('accessToken-ljusgarda');
  if (!token) return false;

  const response = await fetch(`${BACKEND_BASE_URL}/whoami`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  if (response.status !== 200) {
    localStorage.removeItem("accessToken-ljusgarda");
    window.location.href = "/";
  }
  console.log("hej")
  return response.status === 200;

};
