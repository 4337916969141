import { useState, useEffect } from 'react';
import './TotalDeparturesCard.scss';
import { ICarrier, IProducts, IScheduleForDate } from '../../types/api';
import { predefinedVariants } from '../../shared/enums';
import WeekDaySelector from '../../hoc/Datepicker/Datepicker';
import Spinner from '../UI/Spinner/Spinner';

interface ITotalDeparturesCardProps {
  activeFilterDay: number;
  currentFilterDate: Date;
  allSchedules: IScheduleForDate[];
  carriers: ICarrier[];
}

interface TotalByVariant {
  [key: string]: number;
}

function TotalDeparturesCard(props: ITotalDeparturesCardProps) {
  const [totalByVariant, setTotalByVariant] = useState<TotalByVariant>({});
  const [overallTotal, setOverallTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState<Date>(new Date());

  function calculateTotalSaladAmounts(
    productName: string,
    variantName: string
  ) {
    let totalAmount = 0;
    props.allSchedules.forEach((schedule) => {
      schedule.orders.forEach((order) => {
        order.orderDetails.forEach((orderDetail) => {
          if (
            orderDetail.productName === productName &&
            orderDetail.productVariant === variantName
          ) {
            totalAmount += orderDetail.amount;
          }
        });
      });
    });
    return totalAmount;
  }

  useEffect(() => {
    const totals: TotalByVariant = predefinedVariants.reduce((acc, variant) => {
      const productName = variant.product;
      const variantName = variant.productVariant;
      acc[variant.title] = calculateTotalSaladAmounts(productName, variantName);
      return acc;
    }, {} as TotalByVariant);

    setTotalByVariant(totals);

    setOverallTotal(props.allSchedules.length);
    setLoading(false);
  }, [props.allSchedules]);

  if (loading) {
    return <Spinner/>;
  }

  return (
    <div className="total-order-card">
      <div className="total-order-card-top">
        <div className="total-order-card-top-left">
          <span className="text-l-s">Avgångar</span>
          <div className="total-orders-amount-wrapper">
            <div className="total-orders-amount text-m-l">
              {overallTotal} st
            </div>
            {/* <WeekDaySelector
              value={filterDate}
              onChange={setFilterDate}
              includeWeekdays={false}
            /> */}
          </div>
        </div>
      </div>
      <div className="total-order-card-bottom">
        {predefinedVariants.map((variant) => (
          <div key={variant.id} className="salad-dot-wrapper-in-card">
            <div className={`dot ${variant.class}`} />
            <div className="amount-salad-text">
              <div className="text-m-m">
                {`${totalByVariant[variant.title] || 0}`}
              </div>
              <div className="text-m-m text-color-gray">/</div>
              <div className="text-m-m text-color-gray">25</div>{' '}
              {/* Adjust this number as needed */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TotalDeparturesCard;
