import './CreateCampaignPopup.scss';
import React, { useState } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { createCampaign } from '../../api/campaign';
import { ICampaignInfo, ILocationAll, IRetailer } from '../../types/api';

interface ICreateCampaignPopupProps {
  showPopup: boolean;
  onClose: () => void;
  retailers: IRetailer[];
  allLocations: ILocationAll[];
}

function CreateCampaignPopup(props: ICreateCampaignPopupProps) {
  const [name, setName] = useState<string>('');
  const [productVariantId, setProductVariantId] = useState<number>(0);
  const [retailerId, setRetailerId] = useState<number | null>(null);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [contactId, setContactId] = useState<number | null>(null);
  const [validFrom, setValidFrom] = useState<string>('');
  const [validTo, setValidTo] = useState<string>('');
  //   const [bulkGte, setBulkGte] = useState<number>(0);
  //   const [bulkLte, setBulkLte] = useState<number>(0);
  const [priceFixedSek, setPriceFixedSek] = useState<number | null>(null);
  const [priceOffSek, setPriceOffSek] = useState<number | null>(null);
  const [pricePercent, setPricePercent] = useState<number | null>(null);
  const areRequiredFieldsFilled = () => {
    const locationOrRetailerOrContactFilled =
      locationId || retailerId || contactId;
    return (
      name &&
      productVariantId &&
      validFrom &&
      validTo &&
      locationOrRetailerOrContactFilled
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'productVariantId':
        setProductVariantId(Number(value));
        break;
      case 'retailerId':
        setRetailerId(Number(value));
        break;
      case 'locationId':
        setLocationId(Number(value));
        break;
      case 'contactId':
        setContactId(Number(value));
        break;
      case 'validFrom':
        setValidFrom(value);
        break;
      case 'validTo':
        setValidTo(value);
        break;
      //   case 'bulkGte':
      //     setBulkGte(Number(value));
      //     break;
      //   case 'bulkLte':
      //     setBulkLte(Number(value));
      //     break;
      case 'priceFixedSek':
        setPriceFixedSek(Number(value));
        break;
      case 'priceOffSek':
        setPriceOffSek(Number(value));
        break;
      case 'pricePercent':
        setPricePercent(Number(value));
        break;
      default:
        break;
    }
  };

  async function createCampaignHandler() {
    // Construct the campaign object
    const validFromTimestamp = Math.floor(new Date(validFrom).getTime() / 1000);
    const validToTimestamp = Math.floor(new Date(validTo).getTime() / 1000);
    const campaignToCreate = {
      name,
      productVariantId,
      retailerId,
      locationId,
      contactId,
      validFrom: validFromTimestamp,
      validTo: validToTimestamp,
      //   bulkGte,
      //   bulkLte,
      priceFixedSek,
      priceOffSek,
      pricePercent,
    };

    // Call createCampaign API function (to be implemented in your API logic)
    try {
      const response = await createCampaign(campaignToCreate);
      console.log('Campaign created successfully:', response);
      props.onClose();
      // Handle post-creation logic (like reloading the page or updating state)
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Skapa ny kampanj</span>}
    >
      <div className="create-campaign-popup">
        <form
          className="create-campaign-form"
          onSubmit={(e) => {
            e.preventDefault();
            createCampaignHandler();
          }}
        >
          <div className="campaign-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-campaign-name"
              placeholder="Namn"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="campaign-section">
            <span className="text-m-m">Startdatum</span>
            <input
              className="i-campaign-validFrom"
              placeholder="YYYY-MM-DD"
              name="validFrom"
              type="date"
              value={validFrom}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Slutdatum</span>
            <input
              className="i-campaign-validTo"
              placeholder="YYYY-MM-DD"
              name="validTo"
              type="date"
              value={validTo}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Produktvariant</span>
            <select
              className="i-campaign-productVariant"
              placeholder="Produktvariant"
              name="productVariantId"
              value={productVariantId}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj produktvariant
              </option>
              <option value="1">Krisp 60g</option>
              <option value="2">Krisp 150g</option>
              <option value="3">Mix 60g</option>
              <option value="4">Mix 150g</option>
            </select>
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Retailer</span>
            <select
              className="s-campaign-retailer"
              name="retailerId"
              value={retailerId || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj retailer
              </option>
              {props.retailers.map((retailer) => (
                <option key={retailer.id} value={retailer.id}>
                  {retailer.name}
                </option>
              ))}
            </select>
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Namn på butik</span>
            <select
              className="s-campaign-store"
              name="locationId"
              value={locationId || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj butik
              </option>
              {props.allLocations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          {/* 
          <div className="campaign-section">
            <span className="text-m-m">Bulk GTE</span>
            <input
              className="i-campaign-bulkGte"
              placeholder="Bulk GTE"
              name="bulkGte"
              type="number"
              value={bulkGte}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Bulk LTE</span>
            <input
              className="i-campaign-bulkLte"
              placeholder="Bulk LTE"
              name="bulkLte"
              type="number"
              value={bulkLte}
              onChange={handleInputChange}
            />
          </div> */}

          <div className="campaign-section">
            <span className="text-m-m">Fast pris i SEK</span>
            <input
              className="i-campaign-priceFixedSek"
              placeholder="Fast pris i SEK"
              name="priceFixedSek"
              type="number"
              value={priceFixedSek ? priceFixedSek : ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Prisavdrag i SEK</span>
            <input
              className="i-campaign-priceOffSek"
              placeholder="Prisavdrag i SEK"
              name="priceOffSek"
              type="number"
              value={priceOffSek ? priceOffSek : ''}
              onChange={handleInputChange}
            />
          </div>

          <div className="campaign-section">
            <span className="text-m-m">Prisprocent</span>
            <input
              className="i-campaign-pricePercent"
              placeholder="Prisprocent"
              name="pricePercent"
              type="number"
              value={pricePercent ? pricePercent : ''}
              onChange={handleInputChange}
            />
          </div>

          {/* Add form fields for each campaign attribute */}
          <div className="campaign-save-wrapper">
            <button
              className="green-button campaign-save-button"
              type="submit"
              disabled={!areRequiredFieldsFilled()}
            >
              <span className="text-m-m">Skapa och applicera kampanj</span>
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateCampaignPopup;
