import { AxiosInstance } from "axios";
import { ClientJS } from "clientjs";
import axios from "../shared/axios";
import { environment } from "../shared/environment";
import { IApiResponse } from "../types/api";

const PREFIX = "/auth";

export function rehydrateToken({
  axios,
  refreshToken,
}: {
  axios: AxiosInstance;
  refreshToken: string;
}) {
  return axios.post<
    IApiResponse<{ access_token: string; refresh_token: string }>
  >(`${PREFIX}/rehydrate`, {
    refresh_token: refreshToken,
  });
}


export function loginEmailAndPassword({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return axios.post<
    IApiResponse<{ access_token: string; refresh_token: string }>
  >(`${PREFIX}/login`, {
    email,
    password,
  });
}



export function changePassword({
  password,
  secret,
  hash,
}: {
  password: string;
  secret: string;
  hash: string;
}) {
  return axios.post(`${PREFIX}/forgotPassword/callback`, {
    password,
    secret,
    hash,
  });
}

export function getOauthGoogleUrl() {
  return `${environment.apiBaseUrl}${PREFIX}/google`;
}

export function resetPassword(email: string) {
  return axios.post(`${PREFIX}/forgotPassword`, {
    email,
  });
}

export function getRemainingTtl(email: string) {
  return axios.post<IApiResponse<{ remainingTtl: number }>>(
    `${PREFIX}/remainingTtl`,
    {
      email,
    }
  );
}

export function verifyVerificationKey(authKey: string) {
  return axios.post<
    IApiResponse<{
      email: string;
      status: string;
      remainingTtl: number;
    }>
  >(`${PREFIX}/verifyKey`, {
    key: authKey,
  });
}

export function verifyEmail({
  authKey,
  password,
  firstName,
  lastName,
  phoneNr,
}: {
  authKey: string;
  password: string;
  firstName?: string;
  lastName?: string;
  phoneNr?: string;
}) {
  return axios.post<
    IApiResponse<{ access_token: string; refresh_token: string }>
  >(`${PREFIX}/verifyEmail`, {
    password,
    firstName,
    lastName,
    phoneNr,
    key: authKey,
  });
}

export function registerEmail(email: string) {
  return axios.post(`${PREFIX}/registerEmail`, {
    email,
  });
}