import { useState, useEffect } from 'react';
import './SchedulesCard.scss';
import SchedulesOrderCard from './SchedulesOrderCard/SchedulesOrderCard';
import { AggregatedOrder, ICarrierSchedules, IProducts, IScheduleForDate } from '../../types/api';
import { ICarrier } from '../../types/api';
import { formatDateToSwedish, getSwedishWeekday } from '../../shared/utility';
import Button from '../UI/Button/Button';
import { predefinedVariants } from '../../shared/enums';
import { create } from 'domain';
import { IExcelExportData} from '../../types/excel';
import CreateExcelExport from '../../excel/CreateExcelExport';
import EditSchedulePopup from '../../containers/EditSchedulePopup/EditSchedulePopup';

interface ISchedulesCardProps {
  activeFilterDay: number;
  currentFilterDate: Date;
  schedule: IScheduleForDate;
  orders: AggregatedOrder[];
  carriers: ICarrier[];
  onConfirm: () => void;
  onRemoveFromSchedule: () => void;
}

interface TotalByVariant {
  [key: string]: number; // This means any string key will have a number value
}

function SchedulesCard(props: ISchedulesCardProps) {
  const [showEditSchedulePopup, setShowEditSchedulePopup] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<IExcelExportData[]>([]);
  const date = new Date(props.orders[0].shipmentSchedule.carrierPickupEstimate);
 
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(date);

  const carrier = props.carriers.find(carrier => carrier.name === props.orders[0].shipmentSchedule.name)
  
  const carName = props.orders[0].carName;
  
   const [totalByVariant, setTotalByVariant] = useState<TotalByVariant>({});
   const [loading, setLoading] = useState(true);

  //  function calculateTotalSaladAmounts(
  //    productName: string,
  //    variantName: string
  //  ) {
  //    let totalAmount = 0;
  //    props.orders.forEach((aggregatedOrder) => {
  //      if (aggregatedOrder.shipmentStatus !== 1) return;
  //      aggregatedOrder.orderDetails.forEach((orderDetail) => {
  //        if (
  //          orderDetail.productName === productName &&
  //          orderDetail.productVariant === variantName
  //        ) {
  //          totalAmount += orderDetail.amount;
  //        }
  //      });
  //    });
     
  //    return totalAmount;
  //  }
   
   useEffect(() => {
     setLoading(true);
     const totals: TotalByVariant = {};

     predefinedVariants.forEach((variant) => {
       const productName = variant.product;
       const variantName = variant.productVariant;


       totals[variant.title] = 0;

       props.orders.forEach((aggregatedOrder) => {
         if (aggregatedOrder.shipmentStatus !== 0) return;

         aggregatedOrder.orderDetails.forEach((orderDetail) => {
        
           if (
             orderDetail.productName === productName &&
             orderDetail.productVariant === variantName
           ) {

             totals[variant.title] += orderDetail.amount;
           }
         });
       });
       
     });

     setTotalByVariant(totals);
     setLoading(false);
   }, []);
   
   function transformOrdersToExcelData(orders: AggregatedOrder[], carriers: ICarrier[]): IExcelExportData[] {
        
      return orders.map(order => {
        const productDetails = {
          krisp60g: 0,
          mix60g: 0,
          krisp150g: 0,
          mix150g: 0,
        }; 
        order.orderDetails.forEach((detail) => {
          switch (detail.productVariantId) {
            
            case 1:
              productDetails.krisp60g += detail.amount;
              break;
            case 3:
              productDetails.mix60g += detail.amount;
              break;
            case 2:
              productDetails.krisp150g += detail.amount;
              break;
            case 4:
              productDetails.mix150g += detail.amount;
              break;
          }
        });
       
        const distributor = carriers.find(carrier => carrier.id === order.carrierId)?.name || '';
        const deliveryDay = getSwedishWeekday(order.shipmentSchedule.carrierPickupDelivered);
        
       
        return {
          storeName: order.locationName,
          address: order.location.address1,
          city: order.location.city,
          postalCode: order.location.zipCode,
          distributorName: distributor,
          deliveryDay: deliveryDay,
          ...productDetails,
        };
      });
    }
    
    useEffect(() => {
      setExcelData(transformOrdersToExcelData(props.orders, props.carriers));
    }, [props.orders, props.carriers]);
   
    const openEditSchedule = () => {
      setShowEditSchedulePopup(true);
    }; 

  if(loading) {
    return <div>Loading...</div>
  } else {
  
  }
  return (
    <div className="carrier-schedule-card">
      <div className="carrier-schedule-card-top">
        <div className="carrier-schedule-card-top-left">
          <span className="text-l-s">{carName}</span>
          <div className="carrier-schedule-carrier">
            <span className="text-m-m">
              {weekday}, {dateAndMonth} - {hour}
            </span>
          </div>
        </div>
        <div className="carrier-schedule-card-top-right">
          <button
            className="edit-carrier-schedule-button"
            onClick={openEditSchedule}
          >
            <i className="far fa-edit"></i>
          </button>
        </div>
      </div>
      <div className="carrier-schedule-total-card-wrapper">
        {predefinedVariants.map((variant) => {
          return (
            <div
              key={variant.id}
              className="salad-dot-wrapper-in-card darker-bg"
            >
              <div className={`dot ${variant.class}`} />
              <div className="amount-salad-text">
                <div className="text-m-m">
                  {`${totalByVariant[variant.title] || 0}`}
                </div>
                <div className="text-m-m text-color-gray">/</div>
                <div className="text-m-m text-color-gray">25</div>{' '}
              </div>
            </div>
          );
        })}
      </div>
      <div className="carrier-schedule-card-bottom">
        {props.orders.map((order, index) => {
          return <SchedulesOrderCard key={index} order={order} />;
        })}
      </div>
      <div className="carrier-schedule-actions-wrapper">
        <CreateExcelExport excelData={excelData} orders={props.orders} onConfirm={props.onConfirm} confirmShipments={true} label={"Skapa lastlista"}/>
       
      </div>
      <EditSchedulePopup
        showPopup={showEditSchedulePopup}
        onClose={() => setShowEditSchedulePopup(false)}
        orders={props.orders}
        onRemoveFromSchedule={props.onRemoveFromSchedule}
      />
    </div>
  );
}

export default SchedulesCard;


