import { BACKEND_BASE_URL } from "../services/models";
import axios from "../shared/axios";
import { IApiResponse, ICreateShipment, IEditShipmentBulk, IEditShipmentBulkAlt, IShipment, IShipmentSchedule} from "../types/api";


const PREFIX = "/shipment";
const config = {
    headers: {
      'Cache-Control': 'no-cache'
    }
  };


export function listShipments(pickupFrom?: Date, status?: number, locationId?: number, shipmentCreatedAt?: Date) {
 let queryParams = new URLSearchParams();

   function isDaylightSavingTime(date:Date) {
    //
    
    const start = new Date(date.getFullYear(), 2, 31);  
    const end = new Date(date.getFullYear(), 9, 31);    
    start.setDate(31 - (start.getDay() + 1) % 7);
    end.setDate(31 - (end.getDay() + 1) % 7);
    
    return date >= start && date < end;
}
function getSwedishUnixTimestamp(date:Date) {
    const offset = isDaylightSavingTime(date) ? 2 : 1;  
    const utcDate = new Date(date.valueOf() + offset * 3600000);
    return Math.floor(utcDate.getTime() / 1000);
}

if (pickupFrom) {
    const pickupFromDate = new Date(pickupFrom);
    pickupFromDate.setHours(0, 0, 0, 0);
    const pickupToDate = new Date(pickupFromDate.getTime() + (24 * 60 * 60 * 1000));
    const pickupFromUnix = getSwedishUnixTimestamp(pickupFromDate);
    const pickupToUnix = getSwedishUnixTimestamp(pickupToDate);

    
    queryParams.append('pickupFrom', pickupFromUnix.toString());
    queryParams.append('pickupTo', pickupToUnix.toString());
}

  if (status !== undefined) {
    queryParams.append('status', status.toString());
  }
  if (locationId !== undefined) {
    queryParams.append('locationId', locationId.toString());
  }
   if (shipmentCreatedAt !== undefined) {
    queryParams.append('shipmentCreatedAt', shipmentCreatedAt.toString());
  }


  const url = `${BACKEND_BASE_URL}${PREFIX}/list?${queryParams.toString()}&limit=1000&sortBy=updated_at&sortDirection=DESC&`;
  return axios.get<IApiResponse<{ data: IShipment[] }>>(url, config).then((response) => {
    return response.data.payload;
  });
}


export function getShipmentSchedule(id:number) {
    const url = `${BACKEND_BASE_URL}${PREFIX}/schedule/${id}`;
    return axios.get<IApiResponse<IShipmentSchedule>>(url).then((response) => { return response.data.payload;});
}

export function changeShipmentStatus(
  id: number,
  status: number = 0
) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit/`;
  const reqBody = {
    id,
    status
  };

  return axios.post<IApiResponse<IShipment>>(url, reqBody).then(response => {
    return response.data.payload;
  });
}


export function removeShipmentFromSchedule(
  id: number,
  status: number = 5
) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit/`;
  const reqBody = {
    id, 
    status
  };

  return axios.post<IApiResponse<IShipment>>(url, reqBody).then(response => {
    return response.data.payload;
  });
}


export function deleteShipment(id: number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/delete/${id}`;
  return axios.delete<IApiResponse<IShipment>>(url).then(response => {
    return response.data.payload;
  });
}

export function updateShipment(id: number, amount: number, shipmentScheduleId?: number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit`;
  const reqBody = {
    id,
    amount,
    shipmentScheduleId
  };
  return axios.post<IApiResponse<IShipment>>(url,reqBody).then(response => {
    return response.data.payload;
  });
}

export function updateShipmentsBulk(data: IEditShipmentBulk) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit/bulk`;
  return axios.post<IApiResponse<IShipment[]>>(url, data).then(response => {
    return response.data.payload;
  });
}
export function updateShipmentsBulkAlt(data: IEditShipmentBulkAlt) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit/bulk/alt`;
  return axios.post<IApiResponse<IShipment[]>>(url, data).then(response => {
    return response.data.payload;
  });
}

export function createShipment(shipmentToCreate: ICreateShipment) {
    const contactId = shipmentToCreate.contactId ? shipmentToCreate.contactId : 1;

  const url = `${BACKEND_BASE_URL}${PREFIX}/create`;
   const reqBody = {
    locationId: shipmentToCreate.locationId,
    productVariantId: shipmentToCreate.productVariantId,
    amount: shipmentToCreate.amount,
    note: shipmentToCreate.note,
    shipmentScheduleId: shipmentToCreate.shipmentScheduleId,
    status: shipmentToCreate.status,
  };

  return axios.post<IApiResponse<IShipment>>(url,reqBody).then(response => {
    return response.data.payload;
  });
}

interface IShipmentScheduleResponse {
  shipmentScheduleId: number;
}
export function createShipmentSchedule(shipmentSchedule: any) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/schedule/create`;

  return axios.post<IApiResponse<IShipmentScheduleResponse>>(url, shipmentSchedule).then(response => {
    return response.data.payload;
  });
}

export function listShipmentSchedules() {
  const url = `${BACKEND_BASE_URL}${PREFIX}/schedule/list`;
  return axios.get<IApiResponse<{ data: IShipmentSchedule[] }>>(url).then((response) => {
    return response.data.payload;
  });
}