import "./AmountCard.scss";

interface IAmountCardProps {
  variant: {
    id: number;
    title: string;
    class: string;
    product: string;
    productVariant: string;
  };
  index: number;
  getAmountForVariant: (productVariant: string, productName: string) => number;
}

function AmountCard(props: IAmountCardProps) {

  return (
    <div key={props.index} className="salad-dot-wrapper-in-card">
      <div className={`dot ${props.variant.class}`} />
      <div className="amount-salad-text">
        <div className="text-m-m">
          {props.getAmountForVariant(props.variant.productVariant, props.variant.product)}
        </div>
        <div className="text-m-m text-color-gray">/</div>
        <div className="text-m-m text-color-gray">25</div>
      </div>
    </div>
  );
}

export default AmountCard;