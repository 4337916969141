import { on } from 'events';
import Button from '../../components/UI/Button/Button';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';
import Popup from '../../hoc/Popup/Popup';
import './CreateOrderPopup.scss';
import React, { useState } from 'react';
import {
  ICar,
  ICreateShipment,
  ILocationAll,
  ILocationInfo,
} from '../../types/api';
import { createShipment, createShipmentSchedule} from '../../api/shipments';
import { getLocation } from '../../api/locations';
import { combineDateTimeToUnix } from '../../shared/utility';
import WeekDaySelector from '../../hoc/Datepicker/Datepicker';

interface ICreateOrderPopupProps {
  showPopup: boolean;
  onClose: () => void;
  locations: ILocationAll[];
  cars: ICar[];
  onConfirm: () => void;
  filterDate: Date;
}

function CreateOrderPopup(props: ICreateOrderPopupProps) {
  
  //   const [orderDetails, setOrderDetails] = useState(props.order.orderDetails);
  const [weekday, setWeekday] = useState<number>(0); 
  const [locationId, setLocationId] = useState<number>(0);
  const [mix60g, setMix60g] = useState<number>(0);
  const [mix150g, setMix150g] = useState<number>(0);
  const [krisp60g, setKrisp60g] = useState<number>(0);
  const [krisp150g, setKrisp150g] = useState<number>(0);
  const [chosenLocation, setChosenLocation] = useState<ILocationInfo>();  
  const [deliveryName, setDeliveryName] = useState<string>(''); 

  const [pickupDate, setPickupDate] = useState<Date>(new Date(props.filterDate));
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
  const [pickupTime, setPickupTime] = useState<string>('07:00');
  const [deliveryTime, setDeliveryTime] = useState<string>('07:00');

  const pickupTimestamp = combineDateTimeToUnix(pickupDate, pickupTime);
  const deliveryTimestamp = combineDateTimeToUnix(deliveryDate, deliveryTime);


  
const [carId, setCarId] = useState<number | null>(null);


  async function fetchLocation(id:number) {
    const locationInfoRes = await getLocation(id)
    setChosenLocation(locationInfoRes.data)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'locationId':
        setLocationId(Number(value));
        fetchLocation(Number(value));
        break;
      case 'mix60g':
        setMix60g(Number(value));
        break;
      case 'weekday':
        setWeekday(Number(value));
        break;
      case 'pickupTime':
        setPickupTime(value);
        break;
      case 'deliveryTime':
        setDeliveryTime(value);
        break;
      case 'pickupDate':
        setPickupDate(new Date(value));
        break;
      case 'deliveryDate':
        setDeliveryDate(new Date(value));
        break;
      case 'deliveryName':
        setDeliveryName(value);
        break;
      case 'mix150g':
        setMix150g(Number(value));
        break;
      case 'krisp60g':
        setKrisp60g(Number(value));
        break;
      case 'krisp150g':
        setKrisp150g(Number(value));
        break;
      case 'carId':
        setCarId(Number(value));
        break;
      default:
        break;
    }
  };

  async function createOrderHandler() {
    try {
      if (!locationId || !carId) {
        alert('Please select a location and a car');
        return;
      }
      const shipmentScheduleToCreate=  {
        name:deliveryName,
        pickupTimestamp:pickupTimestamp,
        carrierDeliveryEstimate:deliveryTimestamp,
        carrierScheduleId:carId
      };
      const scheduleResponse = await createShipmentSchedule(shipmentScheduleToCreate)
      console.log('scheduleResponse', scheduleResponse)
      
      const orderPromises = [];
      function generateShipmentToCreate(
        variantAmount: number,
        productVariantId: number,
        note: string,
        status: number
      ): ICreateShipment {
        return {
          locationId: locationId,
          productVariantId: productVariantId,
          amount: variantAmount,
          note: note,
          shipmentScheduleId: scheduleResponse.shipmentScheduleId,
          status: status,
        };
      }

      if (mix60g > 0) {
        orderPromises.push(
          createShipment(generateShipmentToCreate(mix60g, 3, '', 5))
        );
      }
      if (mix150g > 0) {
        orderPromises.push(
          createShipment(generateShipmentToCreate(mix150g, 4, "", 5))
        );
      }
      if (krisp60g > 0) {
        orderPromises.push(
          createShipment(generateShipmentToCreate(krisp60g, 1, "", 5))
        );
      }
      if (krisp150g > 0) {
        orderPromises.push(
          createShipment(generateShipmentToCreate(krisp150g, 2, "", 5))
        );
      }

      const results = await Promise.all(orderPromises);

      console.log('Orders created successfully:', results);
      props.onConfirm();
      props.onClose();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  }
 const weekdays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Redigera order</span>}
    >
      <div className="create-order-popup">
        <form
          className="create-order-form"
          onSubmit={(e) => {
            e.preventDefault();
            createOrderHandler();
          }}
        >
          <div className="order-section">
            <select
              className="s-location"
              name="locationId"
              value={locationId || ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Välj butik
              </option>

              {props.locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          <div className="order-section">
            <div className="order-variants-flexer">
              <div className="order-variant-section">
                <div className={`salad-dot-wrapper mix-60`}>
                  <div className={`dot mix-60`} />
                  <span className="text-s-m">Mix (60)</span>
                </div>
                <input
                  type="number"
                  min="0"
                  value={mix60g}
                  name="mix60g"
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-variant-section">
                <div className={`salad-dot-wrapper mix-150`}>
                  <div className={`dot mix-150`} />
                  <span className="text-s-m">Mix (150)</span>
                </div>
                <input
                  type="number"
                  min="0"
                  value={mix150g}
                  name="mix150g"
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-variant-section">
                <div className={`salad-dot-wrapper krisp-60`}>
                  <div className={`dot krisp-60`} />
                  <span className="text-s-m">Krisp (60)</span>
                </div>
                <input
                  type="number"
                  min="0"
                  value={krisp60g}
                  name="krisp60g"
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-variant-section">
                <div className={`salad-dot-wrapper krisp-150`}>
                  <div className={`dot krisp-150`} />
                  <span className="text-s-m">Krisp (150)</span>
                </div>
                <input
                  type="number"
                  min="0"
                  value={krisp150g}
                  name="krisp150g"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="order-section">
            <span className="text-m-m">Namn på leverans</span>
            <input
              className="i-order-name"
              placeholder="Namn"
              name="deliveryName"
              value={deliveryName}
              onChange={handleInputChange}
            />
          </div>
          {chosenLocation &&
            (chosenLocation.carrierSchedules.length > 0 ? (
              <div className="order-section">
                <span className="text-m-m">Bil (Upphämtningsdag)</span>
                <select
                  className="s-order-car"
                  name="carId"
                  value={carId || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Välj bil (Upphämtningsdag)
                  </option>

                  {chosenLocation.carrierSchedules.map((car) => (
                    <option key={car.id} value={car.id}>
                      {car.name} ({car.pickupWeekDay})
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div>Vänligen lägg till en bil till denna butik i butiksvyn</div>
            ))}
          <span> Leveransdag</span>
          <WeekDaySelector value={deliveryDate} onChange={setDeliveryDate} includeWeekdays= {false} />
          <div className="order-section">
            <span className="text-m-m">Leveranstid</span>
            <input
              type="time"
              className="i-order-time"
              name="deliveryTime"
              value={deliveryTime}
              onChange={handleInputChange}
            />
          </div>
          <div className="order-save-delete-wrapper">
            <Button
              onClick={createOrderHandler}
              className="green-button"
              color={'secondary'}
              label={'Skapa order'}
              short={true}
              dontSubmit={true}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateOrderPopup;
