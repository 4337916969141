import { BACKEND_BASE_URL } from "../services/models";
import axios from "../shared/axios";
import { IApiResponse, IRetailer} from "../types/api";

const PREFIX = "/retailer";

export function listRetailers() {
  const url = `${BACKEND_BASE_URL}${PREFIX}/list`;
  return axios.get<IApiResponse<{ data: IRetailer[] }>>(url).then((response) => { return response.data.payload;});
}

export function editRetailer(location: IRetailer) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit`;
  return axios.post<IApiResponse<{ data: IRetailer }>>(url, location).then((response) => { return response.data.payload;});
}

export function createRetailer(data: Partial<IRetailer>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/create`;
  return axios.post<IApiResponse<{ data: IRetailer }>>(url, data).then((response) => { return response.data.payload;});
}


