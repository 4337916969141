export enum LOCAL_STORAGE_KEY {
    Token ="accessToken-ljusgarda",
    RefreshToken = "refreshToken-ljusgarda",
}

export enum ROUTE {
    Index = "/",

    /* Auth */
    Login = "/",
    OAtuhCallback = "/auth/azuread/callback",
    
    /* Shops */
    Locations = "/butiker",
    
    /* Communication */
    Communication = "/kommunikation",

    /* Forcasts */
    Forecast = "/prognos",

    /* Sales */
    Sales = "/forsaljning",

    /* Settings */
    Settings = "/installningar",

}

export enum ROUTE_LABEL {
    Index = "Hem",

    /* Auth */
    Login = "Logga in",
    OAtuhCallback = "OAtuhCallback",

    /* Shops */
    Locations = "Butiker",

    /* Communication */
    Communication = "Kommunikation",

    /* Forcasts */
    Forecast = "Prognos",

    /* Sales */
    Sales = "Försäljning",

    /* Settings */
    Settings = "Inställningar",
}

export enum ShipmentStatus {
  pending = 0, // waiting processing
  planned = 1, // an admin has addedd to shipment list for driver
  shipped = 2, // driver has loaded the shipment into their care
  delivered = 3, // shipment has been delivered to the customer
  delayed = 4, // shipment has been delayed
  cancelled = 5, // shipment has been cancelled
}

export enum Weekday {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6,
}

export const predefinedVariants = [
    {
      id: 1,
      title: 'Mix 60',
      class: 'mix-60',
      product: 'mix',
      productVariant: '60g',
    },
    {
      id: 2,
      title: 'Mix 150',
      class: 'mix-150',
      product: 'mix',
      productVariant: '150g',
    },
    {
      id: 3,
      title: 'Krisp 60',
      class: 'krisp-60',
      product: 'krisp',
      productVariant: '60g',
    },
    {
      id: 4,
      title: 'Krisp 150',
      class: 'krisp-150',
      product: 'krisp',
      productVariant: '150g',
    },
  ];