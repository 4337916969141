import { useState } from 'react';
import './Communication.scss';

interface ICommunicationProps {}

function Communication(props: ICommunicationProps) {
  return (
    <div>
      <h1>Communication</h1>
    </div>
  );
}

export default Communication;
