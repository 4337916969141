import './CreateRetailerPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { IRetailer } from '../../types/api';
import { createRetailer } from '../../api/retailers';


interface ICreateRetailerPopupProps {
  showPopup: boolean;
  onClose: () => void;
  retailers: IRetailer[];
}

function CreateRetailerPopup(props: ICreateRetailerPopupProps) {
  const [name, setName] = useState<string>('');
const [brand, setBrand] = useState<string>('');
    
  
   const handleInputChange = (
     e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
     const { name, value } = e.target;

     switch (name) {
       case 'name':
         setName(value);
         break;
       case 'brand':
         setBrand(value);
         break;
       default:
         break;
     }
   };
  async function createRetailerHandler() {
    const retailerToCreate: Partial<IRetailer> = {
      name,
      brand,
    };

    try {
      await createRetailer(retailerToCreate);
      props.onClose();
    } catch (error) {
      console.error('Error creating retailer', error);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-l-m">Lägg till ny retailer</span>
      }
    >
      <div className="create-retailer-popup">
        <form
          className="create-retailer-form"
          onSubmit={(e) => {
            e.preventDefault();
            createRetailerHandler();
          }}
        >
          <div className="retailer-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-retailer-name"
              placeholder="Namn på retailern"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="retailer-section">
            <span className="text-m-m">Varumärke</span>
            <input
              className="i-retailer-name"
              placeholder="Varumärke"
              name="brand"
              value={brand}
              onChange={handleInputChange}
            />
          </div>
          <div className="retailer-save-wrapper">
            <button className="green-button retailer-save-button" type="submit">
              <span className="text-m-m">Lägg till retailer</span>
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateRetailerPopup;
