import { BACKEND_BASE_URL } from '../services/models';
import axios from '../shared/axios';
import { IApiResponse, ILocation, ILocationAll, ILocationInfo, ILocationsListDetailed } from '../types/api';
const PREFIX = '/location';
const config = {
  headers: {
    'Cache-Control': 'no-cache',
  },
};



export function listLocations(name?:string, page: number = 1, limit: number = 10) {
  
  let queryParams = new URLSearchParams();

  if (name) {
    queryParams.append('name', name);
  }
  const url = `${BACKEND_BASE_URL}${PREFIX}/list?${queryParams.toString()}page=${page}&limit=${limit}`;
  return axios
    .get<
      IApiResponse<{
        data: ILocation[];
        hasNextPage: boolean;
        offset: number;
        page: number;
        totalPages: number;
        totalRows: number;
      }>
    >(url)
    .then((response) => {
      return response.data.payload;
    });
}


export function listAllLocations() {
  const url = `${BACKEND_BASE_URL}${PREFIX}/listAll`;
  return axios
    .get<IApiResponse<{ data: ILocationAll[] }>>(url)
    .then((response) => {
      return response.data.payload;
    });
} 

export function listLocationsDetailed( page: number = 1, limit: number = 50) { 
  const url = `${BACKEND_BASE_URL}${PREFIX}/list/detailed?page=${page}&limit=${limit}&sortBy=name&sortDirection=ASC`; 
  return axios
    .get<
      IApiResponse<{
        data:ILocationsListDetailed[];
        hasNextPage: boolean;
      }>
    >(url, config)
    .then((response) => {
      return response.data.payload;
    });
}


export function getLocation(id: number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/get/${id}`;
  return axios
    .get<IApiResponse<{ data: ILocationInfo }>>(url, config)
    .then((response) => {
      return response.data.payload;
    });
}

export function createLocation(location: Partial<ILocationInfo>) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/create`;
  return axios
    .post<IApiResponse<{ data: ILocationInfo }>>(url, location)
    .then((response) => {
      return response.data.payload;
    });
}

export function editLocation(location: ILocationInfo) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit`;

  return axios
    .post<IApiResponse<{ data: ILocationInfo }>>(url, location)
    .then((response) => {
      return response.data.payload;
    });
}

export function deleteLocation(id: number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/delete/${id}`;
  return axios.delete<IApiResponse<{ data: ILocationInfo }>>(url).then((response) => {
    return response.data.payload;
  });
}