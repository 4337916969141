import './SettingsFundementalPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import Button from '../../components/UI/Button/Button';

interface ISettingsFundementalPopupProps {
  showPopup: boolean;
  onClose: () => void;
}

function SettingsFundementalPopup(props: ISettingsFundementalPopupProps) {

     function handleInputChange() {}

     function addEdgeCase() {}

     function handleSaveSettings() {}

  function settingsFundementalHandler() {}
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-l-m">Grundläggande inställningar</span>
      }
    >
      <div className="settings-fundemental-popup">
        <form
          className="settings-fundemental-form"
          onSubmit={(e) => {
            e.preventDefault();
            settingsFundementalHandler();
          }}
        >
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal plantor i en stor batch</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select
                className="wide-select"
                name=""
                value=""
                onChange={handleInputChange}
              >
                <option value="30"> 12000 </option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal plantor i en liten batch</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select
                className="wide-select"
                name=""
                value=""
                onChange={handleInputChange}
              >
                <option value="30"> 5000</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal stora rum</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 13</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal små rum</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 2</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal gram (liten påse)</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 60g </option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Antal gram (stor påse)</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 150g</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="create-product-buttons-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till ny påsstorlek'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
              <CreateButton
                color={'primary'}
                label={'Lägg till ny produkt'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section last-setting-section">
            <div className="setting-text">
              <span className="text-l-s">Batchutlägg</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standard (St)</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 50 kg</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-30'}
                onClick={addEdgeCase}
              />
            </div>
          </div>

          <div className="save-settings-wrapper">
            <Button
              color="secondary"
              label="Uppdatera information från produktion"
              onClick={handleSaveSettings}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default SettingsFundementalPopup;
