import './EditLocationPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { deleteLocation, editLocation, getLocation } from '../../api/locations';
import {
  ICampaign,
  ICar,
  ILocInfoSchedules,
  ILocationInfo,
} from '../../types/api';
import { IRetailer } from '../../types/api';
import Button from '../../components/UI/Button/Button';
import DeleteButton from '../../components/UI/DeleteButton/DeleteButton';
import { assignCar, getCar, unassignCar } from '../../api/carriersAndCars';
import CreateButton from '../../components/UI/CreateButton/CreateButton';

interface IEditLocationPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onChange: () => void;
  locationId: number;
  cars: ICar[];
  retailers: IRetailer[];
  campaigns: ICampaign[];
}
type ILocEdit = Omit<ILocInfoSchedules, 'toBeRemoved' | 'isNew' | 'amount'> & {
  toBeRemoved?: boolean;
  isNew?: boolean;
  deliveryWeekDay?: number;
  isModified?: boolean;
};

function EditLocationPopup(props: IEditLocationPopupProps) {
  const [name, setName] = useState<string>('');
  const [initialName, setInitialName] = useState<string>('');
  const [retailerId, setRetailerId] = useState<number>(0);
  const [initialRetailerId, setInitialRetailerId] = useState<number>(0);
  const [zipCode, setZipCode] = useState<string>('');
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [score, setScore] = useState<number | null>(null);
  const [scoreOffset, setScoreOffset] = useState<number | null>(null);
  const [customerOrderportalKey, setCustomerOrderportalKey] = useState<
    string | null
  >(null);
  const [fortnoxCustomerNumber, setFortnoxCustomerNumber] = useState<
    number | null
  >(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [createdAt, setCreatedAt] = useState<string>('');
  const [updatedAt, setUpdatedAt] = useState<string>('');
  const [campaign, setCampaign] = useState<any>([]);
  const [isCreatingLocation, setIsCreatingLocation] = useState<boolean>(false);

  
  const [initialCarrierSchedules, setInitialCarrierSchedules] = useState<ILocEdit[]>([]);
  const [carrierSchedules, setCarrierSchedules] = useState<ILocEdit[]>([]);

  const [contactPerson, setContactPerson] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [carId, setCarId] = useState<number | null>(null);
  const [brand, setBrand] = useState<string | undefined>('');
  const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false);

  const checkIfFormDataChanged = () => {
    const initialData = {
      name: initialName,
      retailerId: initialRetailerId,
      zipCode: '',
      address1: '',
      address2: null,
      city: null,
      country: null,
      score: null,
      scoreOffset: null,
      customerOrderportalKey: null,
      fortnoxCustomerNumber: null,
      longitude: null,
      latitude: null,
      carrierSchedules: [],
      contactPerson: '',
      telephone: '',
      comment: '',
    };

    return (
      name !== initialData.name ||
      retailerId !== initialData.retailerId ||
      zipCode !== initialData.zipCode ||
      address1 !== initialData.address1 ||
      address2 !== initialData.address2 ||
      city !== initialData.city ||
      country !== initialData.country ||
      score !== initialData.score ||
      scoreOffset !== initialData.scoreOffset ||
      customerOrderportalKey !== initialData.customerOrderportalKey ||
      fortnoxCustomerNumber !== initialData.fortnoxCustomerNumber ||
      longitude !== initialData.longitude ||
      latitude !== initialData.latitude ||
      JSON.stringify(carrierSchedules) !==
        JSON.stringify(initialData.carrierSchedules) ||
      contactPerson !== initialData.contactPerson ||
      telephone !== initialData.telephone ||
      comment !== initialData.comment
    );
  };

  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'retailerId':
        setRetailerId(Number(value));
        break;
      case 'zipCode':
        setZipCode(value);
        break;
      case 'address1':
        setAddress1(value);
        break;
      case 'address2':
        setAddress2(value);
        break;
      case 'brand':
        setBrand(value);
        break;
      case 'contactPerson':
        setContactPerson(value);
        break;
      case 'telephone':
        setTelephone(value);
        break;
      case 'comment':
        setComment(value);
        break;
      case 'carId':
        setCarId(Number(value));
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'score':
        setScore(value ? Number(value) : 1);
        break;
      case 'scoreOffset':
        setScoreOffset(value ? Number(value) : null);
        break;
      case 'customerOrderportalKey':
        setCustomerOrderportalKey(value);
        break;
      case 'fortnoxCustomerNumber':
        setFortnoxCustomerNumber(value ? Number(value) : null);
        break;
      case 'longitude':
        setLongitude(value ? Number(value) : null);
        break;
      case 'latitude':
        setLatitude(value ? Number(value) : null);
        break;
      case 'carId':
        setCarId(value ? Number(value) : null);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    console.log(initialCarrierSchedules, carrierSchedules )
    setIsFormDataChanged(checkIfFormDataChanged());
  }, [
    name,
    retailerId,
    zipCode,
    address1,
    address2,
    city,
    country,
    score,
    scoreOffset,
    customerOrderportalKey,
    fortnoxCustomerNumber,
    longitude,
    latitude,
    carId,
    contactPerson,
    telephone,
    comment,
    carrierSchedules
  ]);

  useEffect(() => {
    const fetchAndSetLocation = async () => {
      try {
        const response = await getLocation(props.locationId);
        const locationData = response.data;
        console.log(locationData)
        const carDataPromises = locationData.carrierSchedules.map((car) => getCarData(car.id));
        const carsData = await Promise.all(carDataPromises);

        setCarId(locationData.carrierSchedules[0]?.id);
        setName(locationData.name);
        setInitialName(locationData.name);
        setRetailerId(locationData.retailerId);
        setInitialRetailerId(locationData.retailerId);
        setZipCode(locationData.zipcode);
        setAddress1(locationData.address1);
        setAddress2(locationData.address2);
        setCity(locationData.city);
        setCountry(locationData.country);
        setScore(locationData.score);
        setScoreOffset(locationData.scoreOffset);
        setCustomerOrderportalKey(locationData.customerOrderportalKey);
        setFortnoxCustomerNumber(locationData.fortnoxCustomerNumber);
        setLongitude(locationData.longitude);
        setLatitude(locationData.latitude);
        setCreatedAt(locationData.createdAt);
        setUpdatedAt(locationData.updatedAt);
         processCarData(carsData, locationData, props.locationId);

        const brandRetailer = props.retailers.find(
          (retailer) => retailer.id === retailerId
        );
        const brandString = brandRetailer ? brandRetailer.brand : '';

        setBrand(brandString);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchAndSetLocation();
    
  }, [props.locationId]);

  
    async function getCarData(carId: number) {
      return await getCar(carId);
    }
function processCarData(carsData: any[], locationData: ILocationInfo, locationId: number) {
  const processedSchedules = locationData.carrierSchedules.map((schedule) => {
    const matchedCar = carsData.find((car) => car.data.name === schedule.name);
    const matchedSchedule = matchedCar?.data.schedules.find((carSchedule:any) => carSchedule.locationId === locationId);

    return {
      ...schedule,
      deliveryWeekDay: matchedSchedule?.deliveryWeekDay || 0, 
    };
  });

  setCarrierSchedules(processedSchedules);
  setInitialCarrierSchedules(processedSchedules); 
}


const handleCarrierScheduleChange = (
  index: number,
  field: keyof ILocEdit,
  value: string | number
) => {
  const updatedSchedules = [...carrierSchedules];
  updatedSchedules[index] = {
    ...updatedSchedules[index],
    [field]: value,
    isModified: true,
  };
  setCarrierSchedules(updatedSchedules);
};

  const weekdays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];

  const addCarrierSchedule = () => {
    setCarrierSchedules([
      ...carrierSchedules,
      {
        id: 0,
        name: '',
        deliveryWeekDay: 0,
        toBeRemoved: false,
        pickupWeekDay: 0,
        isNew: true,
      },
    ]);
  };
const haveSchedulesChanged = () => {
  return carrierSchedules.some(
    (schedule) => schedule.isNew || schedule.toBeRemoved || schedule.isModified
  );
};
  const removeCarrierSchedule = (index: number) => {
    const updatedSchedules = [...carrierSchedules];
    const schedule = updatedSchedules[index];

    if (schedule.isNew) {
      updatedSchedules.splice(index, 1);
    } else {
      updatedSchedules[index] = { ...schedule, toBeRemoved: true };
    }

    setCarrierSchedules(updatedSchedules);
  };

  async function editLocationHandler() {
    const locationToUpdate: ILocationInfo = {
      carrierSchedules: carrierSchedules,
      id: props.locationId,
      name: name,
      retailerId: retailerId,
      zipcode: zipCode,
      address1: address1,
      address2: address2,
      city: city,
      country: country,
      score: score,
      scoreOffset: scoreOffset,
      customerOrderportalKey: customerOrderportalKey,
      fortnoxCustomerNumber: fortnoxCustomerNumber,
      longitude: longitude,
      latitude: latitude,
      createdAt: createdAt,
      updatedAt: updatedAt,
    };

    try {
      const response = await editLocation(locationToUpdate);
      console.log('Location updated successfully:', response);

      for (let index = 0; index < carrierSchedules.length; index++) {
        const schedule = carrierSchedules[index];
        const initialSchedule = initialCarrierSchedules[index];

        if (schedule.toBeRemoved) {
          await unassignCar({
            carId: schedule.id,
            locationId: props.locationId,
          });
        } else if (schedule.isNew) {
          const carToAssign = {
            locationId: props.locationId,
            carId: schedule.id,
            deliveryWeekDay: schedule.deliveryWeekDay,
          };
          await assignCar(carToAssign);
        } else if (schedule.isModified && initialSchedule) {
          await unassignCar({
            carId: initialSchedule.id,
            locationId: props.locationId,
          });
          const carToAssign = {
            locationId: props.locationId,
            carId: schedule.id,
            deliveryWeekDay: schedule.deliveryWeekDay,
          };
          await assignCar(carToAssign);
        }
      }

      props.onClose();
      props.onChange();
    } catch (error) {
      console.error('Error updating location:', error);
    }
  }


  async function handleLocationDelete(locationId: number) {
    if (window.confirm('Är du säker på att du vill radera butiken?')) {
      try {
        const response = await deleteLocation(locationId);
        props.onClose();
        props.onChange();
        console.log('Location deleted successfully:', response);
      } catch (error) {
        console.error('Error deleting location:', error);
      }
    }
  }
  if (loading && brand !== '') {
    return <div>Loading...</div>;
  } else {
  }
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-l-m">Redigera butik</span>}
    >
      <div className="edit-location-popup">
        <form
          className="edit-location-form"
          onSubmit={(e) => {
            e.preventDefault();
            editLocationHandler();
          }}
        >
          {/* Name */}
          <div className="location-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-location-name"
              placeholder="Namn"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>

          {/* Retailer ID */}
          <div className="location-section">
            <span className="text-m-m">Retailer</span>
            <select
              className="s-location-retailer"
              name="retailerId"
              value={retailerId !== null ? retailerId : ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Välj retailer
              </option>
              {props.retailers.map((retailer) => (
                <option key={retailer.id} value={retailer.id}>
                  {retailer.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="location-section">
            <span className="text-m-m">Varumärke</span>
            <select
              className="s-location-varu"
              name="brand"
              value={brand}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj varumärke
              </option>
              {props.retailers.map((retailer) => (
                <option key={retailer.id} value={retailer.brand}>
                  {retailer.brand}
                </option>
              ))}
            </select>
          </div> */}
          <div className="location-section">
            <span className="text-m-m">Adress</span>
            <input
              className="i-location-address"
              placeholder="Adress"
              name="address1"
              value={address1}
              onChange={handleInputChange}
            />
          </div>

          {/* More fields based on your requirements */}
          {/* Address 2, City, Country, etc. */}

          {/* Priority */}
          <div className="location-section">
            <span className="text-m-m">Prioritet</span>
            <select
              className="s-location-priority"
              name="score"
              value={score !== null && score !== undefined ? score : ''}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Välj prioritet
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>

          {/* <div className="location-section">
            <span className="text-m-m">Val av bil</span>
            <select
              className="s-location-carrier"
              name="carId"
              value={carId || ''}
              onChange={handleInputChange}
            >
              <option value="" className="placeholder">
                Välj bil
              </option>
              {props.cars.map((car) => (
                <option key={car.id} value={car.id}>
                  {car.name}
                </option>
              ))}
            </select>
          </div> */}
          {carrierSchedules.map((schedule, index) => {
            if (schedule.toBeRemoved) return null;
            return (
              <div key={index} className="location-section">
                <span className="text-m-m">Val av bil {index + 1}</span>
                <select
                  className="s-location-carrier"
                  value={schedule.id || ''}
                  onChange={(e) =>
                    handleCarrierScheduleChange(
                      index,
                      'id',
                      Number(e.target.value)
                    )
                  }
                >
                  <option value="" disabled>
                    Välj bil
                  </option>
                  {props.cars.map((car) => (
                    <option key={car.id} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <span className="text-m-m">Leveransdag</span>
                <select
                  value={schedule.deliveryWeekDay || 0}
                  onChange={(e) =>
                    handleCarrierScheduleChange(
                      index,
                      'deliveryWeekDay',
                      Number(e.target.value)
                    )
                  }
                >
                  <option value="" disabled>
                    Välj dag
                  </option>
                  {weekdays.map((day, idx) => (
                    <option key={idx} value={idx}>
                      {day}
                    </option>
                  ))}
                </select>
                <DeleteButton
                  color={'primary'}
                  label={'Ta bort bil'}
                  icon={'del-40'}
                  onClick={() => removeCarrierSchedule(index)}
                  className="popup-btn"
                  dontSubmit={true}
                />
              </div>
            );
          })}
          <CreateButton
            color={'primary'}
            label={'Lägg till bil'}
            icon={'plus-30'}
            onClick={() => addCarrierSchedule()}
            dontSubmit={true}
            className="popup-btn"
          />

          <div className="location-section">
            <span className="text-m-m">Kontaktperson</span>
            <input
              className="i-location-contactperson"
              placeholder="Namn"
              name="contactPerson"
              value={contactPerson}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Telefonnummer</span>
            <input
              className="i-location-telephone"
              placeholder="Tel-nr"
              name="telephone"
              value={telephone}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-section">
            <span className="text-m-m">Kommentar</span>
            <input
              className="i-location-comment"
              placeholder="Kommentar"
              name="comment"
              value={comment}
              onChange={handleInputChange}
            />
          </div>
          <div className="location-save-delete-wrapper">
            <Button
              className="green-button"
              color={'secondary'}
              label={'Spara uppdaterad information'}
              short={true}
              disabled={!isFormDataChanged}
            />

            <DeleteButton
              onClick={() => handleLocationDelete(props.locationId)}
              color={'primary'}
              label={'Radera butik'}
              icon={'del-40'}
              short={true}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default EditLocationPopup;
