import './SettingsProductionPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import CreateButton from '../../components/UI/CreateButton/CreateButton';
import Button from '../../components/UI/Button/Button';

interface ISettingsProductionPopupProps {
  showPopup: boolean;
  onClose: () => void;
}

function SettingsProductionPopup(props: ISettingsProductionPopupProps) {

  function handleInputChange() {
      
  }
  function settingsProductionHandler() {

  }

  function addEdgeCase() {

  }

  function handleSaveSettings() {

  }
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-l-m">Information från produktionen</span>
      }
    >
      <div className="settings-production-popup">
        <form
          className="settings-production-form"
          onSubmit={(e) => {
            e.preventDefault();
            settingsProductionHandler();
          }}
        >
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Krisp - Processpill = wastage</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Krisp (60g) - Påse/timme</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Krisp (150g) - Påse/timme</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Mix (60g) - Påse/timme</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Mix (150g) - Påse/timme</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Produktionskalender</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section">
            <div className="setting-text">
              <span className="text-l-s">Krips (OEE) - Produktionstid</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>
          <div className="settings-section last-setting-section">
            <div className="setting-text">
              <span className="text-l-s">Mix (OEE) - Produktionstid</span>
            </div>
            <div className="setting-input-wrapper">
              <span className="text-m-m"> Standardvecka</span>
              <select name="" value="" onChange={handleInputChange}>
                <option value="30"> 30 st</option>
              </select>
            </div>
            <div className="setting-edgecase-wrapper">
              <CreateButton
                color={'primary'}
                label={'Lägg till undantag'}
                icon={'plus-40'}
                onClick={addEdgeCase}
              />
            </div>
          </div>

          <div className="save-settings-wrapper">
            <Button
              color="secondary"
              label="Uppdatera information från produktion"
              onClick={handleSaveSettings}
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default SettingsProductionPopup;
