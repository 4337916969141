
import { LOCAL_STORAGE_KEY } from "../../shared/enums";
import store from "../index";
import {
  authLogout,
  authStart,
  authSuccess,
} from "../slices/auth";

const { dispatch } = store;

export async function login({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
  adminAccessToken?: string;
  adminRefreshToken?: string;
}) {
  dispatch(authStart());
  localStorage.setItem(LOCAL_STORAGE_KEY.Token, accessToken);
  localStorage.setItem(LOCAL_STORAGE_KEY.RefreshToken, refreshToken);
  


  dispatch(authSuccess({ accessToken}));
}

export function logout() {
  localStorage.removeItem(LOCAL_STORAGE_KEY.Token);
  localStorage.removeItem(LOCAL_STORAGE_KEY.RefreshToken);
  dispatch(authLogout());
}

