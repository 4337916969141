import { useState, useEffect } from 'react';
import './DeparturesCard.scss';
import {
  AggregatedOrder,
  ICarrier,
  IProducts,
  IScheduleForDate,
} from '../../types/api';
import { predefinedVariants } from '../../shared/enums';
import { formatDateToSwedish, getSwedishWeekday } from '../../shared/utility';
import Button from '../UI/Button/Button';
import CreateExcelExport from '../../excel/CreateExcelExport';
import { IExcelExportData } from '../../types/excel';
import {
  changeShipmentStatus,
  updateShipmentsBulkAlt,
} from '../../api/shipments';
import DeleteButton from '../UI/DeleteButton/DeleteButton';

interface IDeparturesCardProps {
  activeFilterDay: number;
  currentFilterDate: Date;
  schedule: IScheduleForDate;
  orders: AggregatedOrder[];
  carriers: ICarrier[];
  onRemoveDeparture: () => void;
  onLastadToggle: () => void;
  lastadStatus: boolean;
}

interface TotalByVariant {
  [key: string]: number;
}

function DeparturesCard(props: IDeparturesCardProps) {
  const [excelData, setExcelData] = useState<IExcelExportData[]>([]);

  const lastadStatus = props.lastadStatus ? 'secondary' : 'ghost';
  const [totalByVariant, setTotalByVariant] = useState<TotalByVariant>({});
  const [loading, setLoading] = useState(true);
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(
    new Date(props.orders[0].shipmentSchedule.carrierPickupEstimate)
  );
  const carName = props.orders[0].carName;
  useEffect(() => {
    setLoading(true);
    const totals: TotalByVariant = {};

    predefinedVariants.forEach((variant) => {
      const productName = variant.product;
      const variantName = variant.productVariant;

      // Initialize total for this variant
      totals[variant.title] = 0;

      props.orders.forEach((aggregatedOrder) => {
        if (
          aggregatedOrder.shipmentStatus !== 1 &&
          aggregatedOrder.shipmentStatus !== 2
        )
          return;

        aggregatedOrder.orderDetails.forEach((orderDetail) => {
          if (
            orderDetail.productName === productName &&
            orderDetail.productVariant === variantName
          ) {
            // Accumulate the total amount for this variant
            totals[variant.title] += orderDetail.amount;
          }
        });
      });
    });

    setTotalByVariant(totals);
    setLoading(false);
  }, [props.orders, props.schedule]);

  function transformOrdersToExcelData(
    orders: AggregatedOrder[],
    carriers: ICarrier[]
  ): IExcelExportData[] {
    return orders.map((order) => {
      const productDetails = {
        krisp60g: 0,
        mix60g: 0,
        krisp150g: 0,
        mix150g: 0,
      };
      order.orderDetails.forEach((detail) => {
        switch (detail.productVariantId) {
          case 1:
            productDetails.krisp60g += detail.amount;
            break;
          case 3:
            productDetails.mix60g += detail.amount;
            break;
          case 2:
            productDetails.krisp150g += detail.amount;
            break;
          case 4:
            productDetails.mix150g += detail.amount;
            break;
        }
      });

      const distributor =
        carriers.find((carrier) => carrier.id === order.carrierId)?.name || '';
      const deliveryDay = getSwedishWeekday(
        order.shipmentSchedule.carrierPickupEstimate
      );

      return {
        storeName: order.locationName,
        address: order.location.address1,
        city: order.location.city,
        postalCode: order.location.zipCode,
        distributorName: distributor,
        deliveryDay: deliveryDay,
        ...productDetails,
      };
    });
  }

  useEffect(() => {
    setExcelData(transformOrdersToExcelData(props.orders, props.carriers));
  }, [props.orders, props.carriers]);

  const toggleOrdersStatus = async () => {
    const orders = props.orders;
    const IDs: number[] = [];
    const currentState = orders[0].shipmentStatus;
    const updatedStatusValue = currentState === 1 ? 2 : 1;
    orders.forEach((order) => {
      order.orderDetails.forEach((orderDetail) => {
        IDs.push(orderDetail.orderId);
      });
    });
    const shipmentsToUpdate = {
      ids: IDs,
      values: { status: updatedStatusValue },
    };

    try {
      await updateShipmentsBulkAlt(shipmentsToUpdate);
      console.log('All shipments confirmed');
      props.onLastadToggle(); // Callback after successful update
    } catch (error) {
      console.error('Error confirming shipments:', error);
    }
  };

  const removeShipmentHandler = async () => {
    const orders = props.orders;
    const IDs: number[] = [];

    orders.forEach((order) => {
      order.orderDetails.forEach((orderDetail) => {
        IDs.push(orderDetail.orderId);
      });
    });
    const shipmentsToUpdate = {
      ids: IDs,
      values: { status: 0 },
    };

    try {
      await updateShipmentsBulkAlt(shipmentsToUpdate);
      console.log('All shipments confirmed');
      props.onRemoveDeparture(); // Callback after successful update
    } catch (error) {
      console.error('Error confirming shipments:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="departures-card">
      <div className="departures-card-top">
        <div className="departures-card-top-left">
          <span className="text-l-s icon-gap">
            {props.lastadStatus ? (
              <i className="fa-regular fa-check"></i>
            ) : (
              <></>
            )}
            {carName}
          </span>
          <span className="text-m-m">
            {weekday}, {dateAndMonth} - {hour}
          </span>
        </div>
        <div className="departures-card-top-left">
          <DeleteButton
            color={'secondary'}
            label={''}
            icon={'del-40'}
            onClick={removeShipmentHandler}
          />
        </div>
      </div>
      <div className="departures-card-total-card-wrapper">
        {predefinedVariants.map((variant) => (
          <div key={variant.id} className="salad-dot-wrapper-in-card darker-bg">
            <div className={`dot ${variant.class}`} />
            <div className="amount-salad-text">
              <div className="text-m-m">
                {`${totalByVariant[variant.title] || 0}`}
              </div>
              <div className="text-m-m text-color-gray">/</div>
              <div className="text-m-m text-color-gray">25</div>{' '}
              {/* Adjust this number as needed */}
            </div>
          </div>
        ))}
      </div>
      <div className="departures-card-actions-wrapper">
        <CreateExcelExport
          excelData={excelData}
          orders={props.orders}
          onConfirm={()=> void 0}
          confirmShipments={false}
          label={'Visa lastlista'}
        />

        <Button
          short={true}
          color={lastadStatus}
          label={'Lastad'}
          onClick={toggleOrdersStatus}
        />
      </div>
    </div>
  );
}

export default DeparturesCard;
