import { setDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from '../../components/UI/Button/Button';
import DateInput from '../../components/UI/DateInput/DateInput';
import './Datepicker.scss';

type Props = {
  value: Date | null;
  onChange: (value: Date) => void;
  includeWeekdays:boolean;
};
function WeekDaySelector(props: Props) {
  const { t } = useTranslation();
  const weekDay = props.value ? props.value.getDay() : null;
  const weekDays = [
    {
      name: 'Måndag',
      value: 1,
    },
    {
      name: 'Tisdag',
      value: 2,
    },
    {
      name: 'Onsdag',
      value: 3,
    },
    {
      name: 'Torsdag',
      value: 4,
    },
    {
      name: 'Fredag',
      value: 5,
    },
    {
      name: 'Lördag',
      value: 6,
    },
    {
      name: 'Söndag',
      value: 0,
    },
  ];
  return (
    <div className="week-day-selector">
      <DateInput
        value={props.value}
        onChange={(value) => {
          if (value) props.onChange(value);
        }}
        width="135px"
        showAllDates
        ignoreUnselectableDates
        showWeekNumbers
      />
      {props.includeWeekdays? weekDays.map((day) => (
        <Button
          key={day.value}
          label={t(`${day.name}`)}
          color={weekDay === day.value ? 'secondary' : 'primary'}
          onClick={() => {
            props.onChange(
              setDay(props.value || new Date(), day.value, {
                weekStartsOn: 1,
              })
            );
          }}
          short
        />
      )) : <></>}
    </div>
  );
}
export default WeekDaySelector;
