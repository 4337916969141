import { BACKEND_BASE_URL } from "../services/models";
import axios from "../shared/axios";
import { IApiResponse, ICampaign, ICampaignInfo} from "../types/api";

const PREFIX = "/campaign";

export function getCampaign(id:number) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/get/${id}`;
  return axios.get<IApiResponse<{ data: ICampaignInfo }>>(url).then((response) => { return response.data.payload;});
}


export function listCampaigns(locationId?: number, retailerId?: number) {
  let url = `${BACKEND_BASE_URL}${PREFIX}/list`;

  const params = new URLSearchParams();

  if (locationId !== undefined) {
    params.append('locationId', locationId.toString());
  }
  if (retailerId !== undefined) {
    params.append('retailerId', retailerId.toString());
  }

  if (params.toString()) {
    url += `?${params}`;
  }

  return axios.get<IApiResponse<{ data: ICampaign[] }>>(url)
    .then((response) => {
      return response.data.payload;
    });
}

export function createCampaign(campaign:any) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/create`;
  return axios.post<IApiResponse<{ data: ICampaignInfo }>>(url, campaign).then((response) => { return response.data.payload;});
}

export function editCampaign(campaign:any) {
  const url = `${BACKEND_BASE_URL}${PREFIX}/edit`;
  return axios.post<IApiResponse<{ data: ICampaignInfo }>>(url, campaign).then((response) => { return response.data.payload;});
}

