import './CreateCarrierPopup.scss';
import React, { useState, useEffect } from 'react';
import Popup from '../../hoc/Popup/Popup';
import { ICarrier } from '../../types/api';

import { createCarrier } from '../../api/carriersAndCars';

interface ICreateCarrierPopupProps {
  showPopup: boolean;
  onClose: () => void;
  carriers: ICarrier[];
}

function CreateCarrierPopup(props: ICreateCarrierPopupProps) {
  const [name, setName] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  async function createCarrierHandler() {
    const carrierToCreate: Partial<ICarrier> = {
      name,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      await createCarrier(carrierToCreate);
      props.onClose();
    } catch (error) {
      console.error('Error creating carrier', error);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-l-m">Lägg till ny distributör</span>
      }
    >
      <div className="create-carrier-popup">
        <form
          className="create-carrier-form"
          onSubmit={(e) => {
            e.preventDefault();
            createCarrierHandler();
          }}
        >
          <div className="carrier-section">
            <span className="text-m-m">Namn</span>
            <input
              className="i-carrier-name"
              placeholder="Namn på distributören"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="carrier-save-wrapper">
            <button className="green-button carrier-save-button" type="submit">
              <span className="text-m-m">Lägg till distributör</span>
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateCarrierPopup;
