import React, { useEffect } from 'react';
import { checkAuth } from '../services/backend/auth';

const AzureCallbackHandler: React.FC = () => {
  useEffect(() => {
    console.log('AzureCallbackHandler mounted');
    const fetchTokens = async () => {
      try {
        const url = window.location.href;
        const accessToken = url.split('accessToken=')[1].split('&')[0];

        console.log('accessToken', accessToken);

        localStorage.setItem('accessToken-ljusgarda', accessToken);

        if (!(await checkAuth())) {
          console.log('Login failed, invalid token');
          window.alert('Login failed, invalid token');
        }

        window.location.href = '/';
      } catch (error) {
        console.log('an error occurred:', error);
      }
    };

    fetchTokens();
  }, []);

  return (
    <div>
      <h1>Processing authentication...</h1>
    </div>
  );
};

export default AzureCallbackHandler;
