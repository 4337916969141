import './TotalSchedulesCard.scss';
import { AggregatedOrder, ICarrier, ICarrierSchedules, IProducts, IScheduleForDate, ISchedules } from '../../types/api';
import { useState, useEffect } from 'react';
import { predefinedVariants } from '../../shared/enums';

interface ITotalSchedulesCardProps {
  activeFilterDay: number;
  currentFilterDate: Date;
  allSchedules: IScheduleForDate[];
  carriers: ICarrier[];
} 

interface TotalByVariant {
  [key: string]: number; 
}

function TotalSchedulesCard(props: ITotalSchedulesCardProps) {

      const [totalByVariant, setTotalByVariant] = useState<TotalByVariant>({});
      const [overallTotal, setOverallTotal] = useState(0);
      const [loading, setLoading] = useState(true);
      const [ordersAmount, setOrdersAmount] = useState(0);


        function calculateTotalSaladAmounts(
          productName: string,
          variantName: string
        ) {
          let totalAmount = 0;
          props.allSchedules.forEach((schedule) => {
            schedule.orders.forEach((order) => {
              order.orderDetails.forEach((orderDetail) => {
                if (
                  orderDetail.productName === productName &&
                  orderDetail.productVariant === variantName
                ) {
                  totalAmount += orderDetail.amount;
                }
              });
            });
          });
          return totalAmount;
        }

        useEffect(() => {
          const totals: TotalByVariant = predefinedVariants.reduce(
            (acc, variant) => {
              const productName = variant.product;
              const variantName = variant.productVariant;
              acc[variant.title] = calculateTotalSaladAmounts(
                productName,
                variantName
              );
              return acc;
            },
            {} as TotalByVariant
          );

          setTotalByVariant(totals);

        
          setOverallTotal(props.allSchedules.length);
          setLoading(false);
        }, [props.allSchedules]);

    if (loading) {
        return <div>Loading...</div>;
    }
     

    return (
      <div className="total-order-card">
        <div className="total-order-card-top">
          <div className="total-order-card-top-left">
            <span className="text-l-s">Lastlistor</span>
            <div className="total-orders-amount-wrapper">
              <div className="total-orders-amount text-m-l">
                {overallTotal} st
              </div>
            </div>
          </div>
        </div>
        <div className="total-order-card-bottom">
          {predefinedVariants.map((variant) => (
            <div key={variant.id} className="salad-dot-wrapper-in-card">
              <div className={`dot ${variant.class}`} />
              <div className="amount-salad-text">
                <div className="text-m-m">
                  {`${totalByVariant[variant.title] || 0}`}
                </div>
                <div className="text-m-m text-color-gray">/</div>
                <div className="text-m-m text-color-gray">25</div>{' '}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
    
}
export default TotalSchedulesCard;