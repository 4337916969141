import React from 'react';
import styles from '../styles/Login.module.css';
import { BACKEND_BASE_URL, REDIRECT_URL } from '../services/models';

const Login: React.FC = () => {
  const handleLoginClick = () => {
    window.location.href = `${BACKEND_BASE_URL}/auth/azure?redirectUrl=${REDIRECT_URL}`;
  };

  return (
    <div className={styles.loginContainer}>
      <h2>Login</h2>
      <button onClick={handleLoginClick}>Login with Azure AD</button>
    </div>
  );
};


export default Login;
