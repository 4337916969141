import Navbar from '../../containers/Navbar/Navbar';
import './Layout.scss';

interface ILayoutProps {
  children: React.ReactNode;
}

function Layout(props: ILayoutProps) {
  return (
    <div className="app-wrapper">
      <Navbar />
      <div className="content">{props.children}</div>
    </div>
  );
}

export default Layout;
