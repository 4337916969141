import "./DeleteButton.scss"
import {  IconTypeDel } from '../../../types/internal';
import Spinner from '../Spinner/Spinner';

interface DeleteButtonProps {
  onClick?: () => void;
  color: 'secondary' | 'primary';
  label: string;
  className?: string;
  width?: string;
  isLoading?: boolean;
  icon?: IconTypeDel;
  short?: boolean;
  disabled?: boolean;
  dontSubmit?: boolean;
}

function DeleteButton(props: DeleteButtonProps) {
  const buttonClasses = ['delete-button', 'text-m-m', props.color];
  if (props.className) {
    buttonClasses.push(props.className);
  }
  if (props.isLoading) {
    buttonClasses.push('loading');
  }

  if (props.disabled) {
    buttonClasses.push('disabled');
  }
  if (!props.disabled) {
    buttonClasses.push('waves-effect');
  }

  let icon: JSX.Element | undefined;
  switch (props.icon) {
    case 'del-40':
      icon = (
        <div className="delete-button-cricle-40">
          <i className="fa-light fa-trash-can"></i>
        </div>
      );
      break;
  }

  if (icon) {
    buttonClasses.push('icon-button');
  }

  return (
    <button
      className={buttonClasses.join(' ')}
      style={{ width: props.width, height: props.short ? 20 : 30 }}
      onClick={props.disabled ? undefined : props.onClick}
      type={props.dontSubmit ? 'button' : 'submit'}
    >
      {props.isLoading ? (
        <Spinner />
      ) : icon ? (
        <div className="delete-button-content">
          {props.label}
          {icon}
        </div>
      ) : (
        props.label
      )}
    </button>
  );
}

export default DeleteButton;
