import { ClientJS } from "clientjs";
import { compareAsc, fromUnixTime, lightFormat } from "date-fns";
import { environment } from "./environment";
import { Weekday } from "./enums";

export function convertToNumberWithSpaces(num: number, ending?: string) {
  return `${Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}

export function convertToNumberWithSpacesDecimals(
  num: number,
  ending?: string
) {
  return `${num
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}

export function shortenNumber(number: number): string {
  if (number >= 1000000 || number <= -1000000) {
    return (number / 1000000).toFixed(1) + " mkr";
  } else if (number >= 100000 || number <= -100000) {
    return (number / 1000).toFixed(0) + " tkr";
  } else {
    return convertToNumberWithSpaces(number);
  }
}

export function getUnixTime(date: Date) {
  
const timestamp = Math.floor(date.getTime() / 1000);
 
return timestamp;
}

// export function isOnDev() {
//   return environment.appEnv === "development";
// }

export function convertToDropdown<T>({
  list,
  labelKey,
  valueKey,
}: {
  list: T[];
  labelKey: keyof T;
  valueKey: keyof T;
}) {
  return list.map((item) => ({
    label: `${item[labelKey]}`,
    value: `${item[valueKey]}`,
  }));
}

export function monthISO(month: string) {
  return `0${month}`.slice(-2);
}

/**
 * Get a date from a string format. If month date is not specifide it will be set to 1
 * IOS compatible
 * @param dateString Supported formats "2020-1-1" "2020-1"
 * @returns IOS compatible date
 */
export function stringToDate(dateString: string) {
  const dateArr = dateString.split("-");
  const [year, month, day] = dateArr;
  const date = new Date(+year, +month - 1, +day || 1, 0, 0, 0, 0);
  return date;
}

/**
 * Check if a date is after another date
 * @param date The date to check if it is after the compareDate
 * @param compareDate The date to compare to
 * @returns boolean
 */
export function isDateAfter(date: Date, compareDate: Date) {
  const r = compareAsc(date, compareDate);
  return r === 1 || r === 0;
}

export function formatKeyForCompare(dateKey: string, noFormat?: boolean) {
  if (noFormat) return dateKey;

  const splittedKey = dateKey.split("-");
  return `${+splittedKey[0] - 1}-${splittedKey[1]}`;
}


export function isMacUser() {
  const client = new ClientJS();
  return client.isMac();
}

export function getLocale() {
  return navigator.language;
}
export function isValidPhoneNumber(phoneNr: string) {
  const regexPhoneNr = /^\+?[0-9]{1,3}?[0-9]{8,12}$/;
  return regexPhoneNr.test(phoneNr);
}

export function isValidEmail(email: string) {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
}


export function getWeekdayFromDate(dateString: string): Weekday {
    const date = new Date(dateString);
    
    const dayOfWeek = date.getDay();
    return (dayOfWeek === 0) ? Weekday.Sunday : dayOfWeek - 1;
}

export function makeWeekdayActive(dateString: string): Weekday {
    const currentWeekday = getWeekdayFromDate(dateString);
    return currentWeekday;
}


export function formatDateToSwedish(date:Date){
  const newDate = new Date(date);

  const weekday =
    newDate
      .toLocaleString('sv-SE', { weekday: 'long' })
      .charAt(0)
      .toUpperCase() +
    newDate.toLocaleString('sv-SE', { weekday: 'long' }).slice(1);
  const dateAndMonth = newDate.toLocaleString('sv-SE', {
    day: 'numeric',
    month: 'long',
  });
  const hour = newDate
    .toLocaleString('sv-SE', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(':', '.');

  return { weekday, dateAndMonth, hour };
};


export function getSwedishWeekday(dateString:string) {
  const date = new Date(dateString);
  const swedishWeekdays = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];
  return swedishWeekdays[date.getDay()];
}

export const combineDateTimeToUnix = (date: Date, time: string) => {
  const timeParts = time.split(':');
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  date.setHours(hours, minutes, 0); 

  return Math.floor(date.getTime() / 1000);
};
