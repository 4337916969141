import {useState } from 'react';
import "./Sales.scss";

interface ISalesProps {}

function Sales(props: ISalesProps) {
  return (
    <div>
      <h1>Sales</h1>
    </div>
  );
}

export default Sales;